/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, lazy, Suspense} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_gori/assets/ts/_utils'
import {DisableSidebar} from '../../_gori/layout/core'
import {App} from '../App'
import {AuthPage, Logout, useAuth} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {PrivateRoutes} from './PrivateRoutes'
import {LandingPage} from '../pages/landing/LandingPage'
import {ServiceTermsPage} from '../pages/landing/ServiceTermsPage'
import {PrivacyPolicyPage} from '../pages/landing/PrivacyPolicyPage'
import {RefreshTokenPage} from '../pages/refresh-token/RefreshTokenPage'
import {GlobalShopifyVertifyPage} from '../pages/global-shopify-vertify/GlobalShopifyVertifyPage'
import {LoginPage} from '../pages/login/LoginPage'
import {MasterLayoutForNoAuth} from '../../_gori/layout/MasterLayoutForNoAuth'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const DocsWrapper = lazy(() => import('../pages/docs/DocsWrapper'))
  const SupportWrapper = lazy(() => import('../pages/support/SupportWrapper'))
  const {currentUser} = useAuth()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='landing/*' element={<LandingPage />} />
          <Route path='service-terms/*' element={<ServiceTermsPage />} />
          <Route path='privacy-policy/*' element={<PrivacyPolicyPage />} />
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='refresh-token/*' element={<RefreshTokenPage />} />
          <Route path='login-page/*' element={<LoginPage />} />
          <Route path='global/shopify/verify/*' element={<GlobalShopifyVertifyPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <Route path='/*' element={<PrivateRoutes />} />
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/landing' />} />
              <Route element={<MasterLayoutForNoAuth />}>
                <Route
                  path='docs/*'
                  element={
                    <SuspenseView>
                      <DocsWrapper />
                    </SuspenseView>
                  }
                />
                <Route
                  path='support/*'
                  element={
                    <SuspenseView>
                      <SupportWrapper />
                    </SuspenseView>
                  }
                />
              </Route>
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const SuspenseView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {AppRoutes, SuspenseView}
