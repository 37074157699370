export const OrderStatus = {
  OPEN: 'open',
  SHIPPED: 'shipped',
  AWAITING_PAYMENT: 'awaiting_payment',
  ON_HOLD: 'on_hold',
  CANCELLED: 'cancelled',
  ERROR: 'error',
}

export const OrdersConfig = {
  PAGE_SIZE_ADDRESS: 200,
  CREATE_ALL_LABEL: 10,
  SHIPPING: {
    COMPANY: {label: 'Company', value: 'company'},
    ADDRESS: {label: 'Address', value: 'street1', required: true},
    FIRST_NAME: {label: 'First Name', value: 'first_name'},
    ADDRESS_2: {label: 'Address 2', value: 'street2'},
    LAST_NAME: {label: 'Last Name', value: 'last_name'},
    CITY: {label: 'City', value: 'city', required: true},
    EMAIL: {label: 'Email', value: 'email'},
    STATE: {label: 'State', value: 'state', required: true},
    PHONE: {label: 'Phone', value: 'phone'},
    COUNTRY: {label: 'Country', value: 'country'},
    ZIP_CODE: {label: 'Postal Code', value: 'zip', required: true},
    RESIDENTIAL: {label: 'Residential', value: 'is_residential'},
    VERIFIED: {label: 'Verified', value: 'is_verified'},
    SAVE_ADDRESS: {label: 'Save Address', value: 'type'},
  },
  VALIDATE_ADDRESS: ['city', 'country', 'state', 'street1', 'street2', 'zip'],
  PARCEL: ['length', 'width', 'height', 'weight', 'dimension_unit', 'weight_unit'],
  ITEMS: {
    SKU: {label: 'SKU', value: 'sku', type: 'string'},
    TITLE: {label: 'Name', value: 'title', type: 'string'},
    QUANTITY: {label: 'Quantity', value: 'quantity', type: 'number'},
    PRICE: {label: 'Price', value: 'unit_price', type: 'number'},
  },
  CUSTOMS: {
    CONTENT: {label: 'CONTENT', value: 'content', type: 'text'},
    SIGNER: {label: 'SIGNER', value: 'signer', type: 'text'},
    DESCRIPTION: {label: 'DESCRIPTION', value: 'description', type: 'text'},
    ITEMS: {
      DESCRIPTION: {label: 'DESCRIPTION', value: 'description', type: 'text', required: true},
      HS_TARIFF_NUMBER: {
        label: 'HS_TARIFF_NUMBER',
        value: 'hs_tariff_number',
        type: 'text',
        required: false,
      },
      COUNTRY_OF_ORIGIN: {
        label: 'COUNTRY_OF_ORIGIN',
        value: 'country',
        type: 'text',
        required: false,
      },
      CODE: {label: 'CODE', value: 'code', type: 'text', required: false},
      QUANTITY: {label: 'QUANTITY', value: 'quantity', type: 'number', required: true},
      VALUE: {label: 'VALUE', value: 'value', type: 'number', required: true},
      WEIGHT: {label: 'WEIGHT', value: 'weight', type: 'number', required: true},
    },
  },
  US_TERRITORIES: {
    STATE: ['AS', 'FM', 'GU', 'MH', 'MP', 'PW', 'PR', 'VI'],
    CITY: ['APO', 'FPO', 'DPO'],
  },
  MASS_UPDATE: {
    PRESET: {label: 'Preset', value: 'preset'},
    SHIP_FROM: {label: 'Ship From', value: 'from'},
    SHIP_TO: {label: 'Ship To', value: 'to'},
    SERVICE: {label: 'Service', value: 'service'},
    PACKAGE: {label: 'Package', value: 'package_type'},
    WEIGHT: {label: 'Weight', value: 'weight'},
    SIZE: {label: 'Size', value: 'parcels'},
  },
}

export const OPTION_INSURANCE_TYPE = [
  {label: 'None', text: 'None', value: null},
  {label: 'Carrier', text: 'Carrier', value: 'carrier'},
]

export const OPTION_DIMENSION_UNIT = [
  {label: 'in', value: 'in'},
  {label: 'cm', value: 'cm'},
  {label: 'mm', value: 'mm'},
]

export const OPTION_WEIGHT_UNIT = [
  {label: 'oz', value: 'oz'},
  {label: 'lb', value: 'lb'},
  {label: 'gm', value: 'gm'},
  {label: 'kg', value: 'kg'},
]

export const UNIT_VALUES = {
  WEIGHT_CARRIER: {
    oz: {max: 1120},
    lb: {max: 70},
    gm: {max: 31751},
    kg: {max: 31.5},
  },
  WEIGHT: {
    oz: {max: 10000},
    lb: {max: 625},
    gm: {max: 283495},
    kg: {max: 283.5},
  },
  DIMENSION: {
    in: {max: 100},
    cm: {max: 254},
    mm: {max: 2540},
  },
}

export const SIGNATURE_TYPE = {
  SIGNATURE: 'signature',
}

export const OPTIONS_CONTENT_TYPE = [
  {label: 'Merchandise', value: 'merchandise'},
  {label: 'Gift', value: 'gift'},
  {label: 'Documents', value: 'documents'},
  {label: 'Sample', value: 'sample'},
  {label: 'Return', value: 'return'},
  {label: 'Humanitarian', value: 'humanitarian'},
  {label: 'Dangerous Goods', value: 'dangerousgoods'},
  {label: 'Other', value: 'other'},
]

export const OPTION_PACKAGE_TYPE = {
  INPUT_DIMENSION: 'Input Dimension',
  CUSTOM_PACKAGE: 'Custom Package',
  CARRIER_STANDARD_PACKAGE: 'Carrier Standard Package',
}
