import React, {FC} from 'react'

import clsx from 'clsx'

type Props = {
  className?: string
  labelClassName?: string | null
  label?: string | null
  size?: 'sm' | 'lg' | null
  type?: 'password' | 'text' | 'number'
  required?: boolean
  append?: string
  bgSolid?: boolean
  onChange?: any
  value?: any
  disabled?: boolean
  placeholder?: string
  onkeydown?: any
}

const InputTextNoFormik: FC<Props> = ({
  className,
  labelClassName = null,
  label = null,
  size = null,
  type = 'text',
  append,
  required = false,
  bgSolid = false,
  onChange,
  value,
  disabled,
  placeholder,
  onkeydown,
}) => {
  return (
    <>
      {label || labelClassName ? (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      ) : (
        ''
      )}
      <div className={className}>
        <div className='input-group'>
          <input
            type={type}
            autoComplete='off'
            className={clsx(`form-control form-control-${size}`, {
              'form-control-solid': bgSolid,
              'cursor-no-drop': disabled,
            })}
            onChange={onChange}
            defaultValue={value}
            disabled={disabled}
            onKeyDown={onkeydown}
            placeholder={placeholder ? placeholder : ''}
          />
          {append ? (
            <div className='input-group-append'>
              <span className='input-group-text'>{append}</span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}

export {InputTextNoFormik}
