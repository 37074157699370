import {lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {allowTestOnly} from '../../_gori/helpers'
import UseRoutesPermission from '../../_gori/hooks/UseRoutesPermission'
import {MasterLayout} from '../../_gori/layout/MasterLayout'
import displayConfig from '../../displayconfig.json'
import {useAuth} from '../modules/auth'
import {SuspenseView} from './AppRoutes'

const PrivateRoutes = () => {
  const DashboardWrapper = lazy(() => import('../pages/dashboard/DashboardWrapper'))
  const OrdersWrapper = lazy(() => import('../pages/orders/OrdersWrapper'))
  const ShipmentsWrapper = lazy(() => import('../pages/shipments/ShipmentsWrapper'))
  const BatchesWrapper = lazy(() => import('../pages/batches/BatchesWrapper'))
  const ClaimsWrapper = lazy(() => import('../pages/claims/ClaimsWrapper'))
  const DocsWrapper = lazy(() => import('../pages/docs/DocsWrapper'))
  const ReportsWrapper = lazy(() => import('../pages/reports/ReportsWrapper'))
  const SupportWrapper = lazy(() => import('../pages/support/SupportWrapper'))
  const NightWatchWrapper = lazy(() => import('../pages/night-watch/NightWatchWrapper'))
  const SettingsWrapper = lazy(() => import('../pages/settings/SettingsWrapper'))
  const ProfileWrapper = lazy(() => import('../pages/profile/ProfileWrapper'))
  const DepositWrapper = lazy(() => import('../pages/deposit/DepositWrapper'))
  const {routes, firstPage} = UseRoutesPermission()
  const {currentUser} = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Pages */}
        {routes.DASHBOARD?.hasPermission && (
          <Route
            path='dashboard'
            element={
              <SuspenseView>
                <DashboardWrapper />
              </SuspenseView>
            }
          />
        )}
        {routes.ORDERS?.hasPermission && (
          <Route
            path='orders/*'
            element={
              <SuspenseView>
                <OrdersWrapper />
              </SuspenseView>
            }
          />
        )}
        {routes.SHIPMENTS?.hasPermission && (
          <Route
            path='shipments'
            element={
              <SuspenseView>
                <ShipmentsWrapper />
              </SuspenseView>
            }
          />
        )}
        {routes.BATCHES.hasPermission && (
          <Route
            path='batches/*'
            element={
              <SuspenseView>
                <BatchesWrapper />
              </SuspenseView>
            }
          />
        )}
        {routes.CLAIMS?.hasPermission && (
          <Route
            path='claims/*'
            element={
              <SuspenseView>
                <ClaimsWrapper />
              </SuspenseView>
            }
          />
        )}
        {(routes.REPORTS_SHIPMENT?.hasPermission || routes.REPORTS_TRANSACTIONS?.hasPermission) && (
          <Route
            path='reports/*'
            element={
              <SuspenseView>
                <ReportsWrapper />
              </SuspenseView>
            }
          />
        )}
        {routes.PAYMENTS?.hasPermission && (
          <Route
            path='/deposits'
            element={
              <SuspenseView>
                <DepositWrapper />
              </SuspenseView>
            }
          />
        )}
        <Route
          path='/my-profile'
          element={
            <SuspenseView>
              <ProfileWrapper />
            </SuspenseView>
          }
        />
        {(allowTestOnly(currentUser) ||
          (displayConfig.items.nightWatch && routes.NIGHT_WATCH?.hasPermission)) && (
          <Route
            path='nightwatch/*'
            element={
              <SuspenseView>
                <NightWatchWrapper />
              </SuspenseView>
            }
          />
        )}
        {(routes.SETTINGS.hasPermission ||
          routes.USERS.hasPermission ||
          routes.SHIPPING_ADDRESSES.hasPermission) && (
          <Route
            path='settings/*'
            element={
              <SuspenseView>
                <SettingsWrapper />
              </SuspenseView>
            }
          />
        )}
        {routes.DOCS.hasPermission && (
          <Route
            path='docs/*'
            element={
              <SuspenseView>
                <DocsWrapper />
              </SuspenseView>
            }
          />
        )}
        {routes.SUPPORTS.hasPermission && (
          <Route
            path='support/*'
            element={
              <SuspenseView>
                <SupportWrapper />
              </SuspenseView>
            }
          />
        )}
        {/* Redirect to Dashboard for all pages that are not available */}
        <Route path='*' element={<Navigate to={firstPage?.route} />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
