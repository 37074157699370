import React, {FC} from 'react'
import classNames from 'classnames'
import {PaginateRange} from './PaginateRange'
import {PaginateModel} from './core/_models'

type Props = {
  onPageChange: any
  className?: string
  pagination: PaginateModel
  pageNumber?: number
}

const PaginateWidget: FC<Props> = ({
  onPageChange,
  className,
  pagination,
  pageNumber = Number(process.env.REACT_APP_PAGINATION_PAGE_NUMBER),
}) => {
  const paginationRange: any = PaginateRange({
    pagination,
    pageNumber,
  })
  const currentPage = pagination.page
  const lastPage = pagination.total_pages

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    if (currentPage !== lastPage) {
      onPageChange(currentPage + 1)
    }
  }

  const onLast = () => {
    if (currentPage !== lastPage) {
      onPageChange(lastPage)
    }
  }

  const onPrevious = () => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1)
    }
  }

  const onFirst = () => {
    if (currentPage !== 1) {
      onPageChange(1)
    }
  }

  return (
    <div className={className}>
      <ul className='pagination'>
        <li
          key={'first-page'}
          className={classNames('page-item previous', {
            disabled: currentPage === 1,
            'cursor-no-drop': currentPage === 1,
          })}
          onClick={() => onFirst()}
        >
          <button className='page-link'>
            <i className='previous' />
            <i className='previous' />
          </button>
        </li>
        <li
          key={'previous-page'}
          className={classNames('page-item previous', {
            disabled: currentPage === 1,
            'cursor-no-drop': currentPage === 1,
          })}
          onClick={() => onPrevious()}
        >
          <button className='page-link'>
            <i className='previous' />
          </button>
        </li>
        {paginationRange.map((pageNumber: number) => {
          return (
            <li
              key={pageNumber}
              className={classNames('page-item', {
                active: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              <button className='page-link'>{pageNumber}</button>
            </li>
          )
        })}
        <li
          key={'next-page'}
          className={classNames('page-item next', {
            disabled: currentPage === lastPage,
            'cursor-no-drop': currentPage === lastPage,
          })}
          onClick={() => onNext()}
        >
          <button className='page-link'>
            <i className='next' />
          </button>
        </li>
        <li
          key={'last-page'}
          className={classNames('page-item next', {
            disabled: currentPage === lastPage,
            'cursor-no-drop': currentPage === lastPage,
          })}
          onClick={() => onLast()}
        >
          <button className='page-link'>
            <i className='next' />
            <i className='next' />
          </button>
        </li>
      </ul>
    </div>
  )
}

export {PaginateWidget}
