import _ from 'lodash'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Button} from '../../../../_gori/partials/widgets'

type Props = {
  handleClose: () => void
  response?: any
  submitFormCustom?: any
}
const ConfirmAssignOrderModal: React.FC<Props> = ({handleClose, response, submitFormCustom}) => {
  const intl = useIntl()
  const [isLoadingForm] = useState<boolean>(false)
  const handleCloseModal = () => {
    handleClose()
  }
  return (
    <Modal
      id='gori_order_modal_confirm_assign_order'
      tabIndex={-1}
      aria-hidden='true'
      centered
      show={!_.isEmpty(response)}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'WARNING'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            There are some orders that are assigned to other user, This action will overwrite the
            assigned user.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <Button
              className='btn btn-secondary me-2'
              label={intl.formatMessage({id: 'CANCEL'})}
              loadingText={intl.formatMessage({id: 'CANCEL'})}
              event={handleCloseModal}
              disabled={isLoadingForm}
            />
            <Button
              className='btn btn-primary'
              label={intl.formatMessage({id: 'OK'})}
              loadingText={intl.formatMessage({id: 'OK'})}
              loading={isLoadingForm}
              event={submitFormCustom}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export {ConfirmAssignOrderModal}
