import {intersection, isEmpty} from 'lodash'
import {useMemo} from 'react'
import {useAuth} from '../../app/modules/auth'
import {ROUTING_PERMISSIONS} from '../constants'
import {useStatesGlobal} from '../helpers/components/StatesGlobalProvider'

const UseRoutesPermission = () => {
  const {currentUser} = useAuth()
  const permission = currentUser?.permissions
  const {handleUpdateRoutes} = useStatesGlobal()

  const routes: any = useMemo(() => {
    const _routes = Object.entries(ROUTING_PERMISSIONS).reduce(
      (initValue, [key, value]: [any, any]) => {
        initValue[key] =
          !isEmpty(intersection(value?.roles, permission)) ||
          currentUser?.role === 'Developer' ||
          currentUser?.role === 'Seller'
            ? {...value, hasPermission: true}
            : {...value}
        return initValue
      },
      {}
    )

    handleUpdateRoutes(_routes)
    return _routes
  }, [currentUser?.role, handleUpdateRoutes, permission])

  const firstPage = useMemo(() => {
    const keyFirst: any = Object.keys(routes).find((page) => routes[page].hasPermission === true)
    return routes[keyFirst]
  }, [routes])

  return {routes: routes, firstPage: firstPage}
}

export default UseRoutesPermission
