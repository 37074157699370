import clsx from 'clsx'
import {useState} from 'react'
import {Select2, Select2Data, Select2Option} from 'select2-react-component/dist/select2'
import {CompanyModel, getAuth, useAuth} from '../../../app/modules/auth'
import {StorageHelpers} from '../StorageHelpers'

const CURRENT_COMPANY_STORAGE_KEY =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE_KEY || 'CURRENT_COMPANY_STORAGE_KEY'
type Props = {
  isAside: boolean
  className?: string
}
const SwitchCompany: React.FC<Props> = ({isAside, className = ''}) => {
  const {currentUser} = useAuth()
  const [isLoading] = useState(false)

  const listCompany = currentUser?.companies.map((item: CompanyModel) => ({
    label: item.name,
    value: item.encrypt_key,
  }))
  const [filterCompany, setFilterCompany] = useState<any>(listCompany)

  const searchOptions = (options: Select2Option[] | undefined, text: string) => {
    return text
      ? options?.filter((option) => option.label.toLowerCase().indexOf(text.toLowerCase()) > -1)
      : options
  }

  const handleSetFilterOptions = (options: any) => {
    let newOptions = Array.from(options, (option: any) => {
      return {
        value: option.value,
        label: option.label,
      }
    })
    setFilterCompany(newOptions)
  }

  const handleSwitchCompany = (updateVal: any) => {
    const auth = getAuth()
    const choosen =
      currentUser?.companies.filter((item: any) => item.encrypt_key === updateVal)[0] || undefined
    const url = document.location.host
    const protocal = document.location.protocol
    document.location.replace(
      protocal +
        '//' +
        choosen?.slug +
        '.' +
        url.split('.').slice(1).join('.') +
        '/refresh-token/?slug=' +
        choosen?.slug +
        '&token=' +
        auth?.refresh_token
    )
  }

  return (
    <div
      className={clsx(`form-floating border rounded ${className}`, {
        'cursor-no-drop': isLoading,
        'switch-company': isAside,
      })}
    >
      {listCompany && listCompany?.length > 1 && (
        <Select2
          placeholder={filterCompany[0] && filterCompany[0].label}
          customSearchEnabled
          update={handleSwitchCompany}
          disabled={isLoading}
          value={
            StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY)
              ? StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY)
              : filterCompany[0].value
          }
          data={filterCompany as Select2Data}
          open={() => handleSetFilterOptions(listCompany)}
          search={(text) => handleSetFilterOptions(searchOptions(listCompany, text))}
        />
      )}

      {listCompany && listCompany?.length <= 1 && (
        <>
          <div className='form-select border border-hover-info rounded bg-white bg-opacity-50 pt-0 pb-0 cursor-no-drop d-flex align-items-center'>
            {listCompany[0].label}
          </div>
        </>
      )}
    </div>
  )
}

export {SwitchCompany}
