import React from 'react'
import {Spinner} from 'react-bootstrap'
import {useIntl} from 'react-intl'

const Loader = () => {
  const intl = useIntl()

  return (
    <div className='text-center'>
      <Spinner animation='border' role='status' style={{width: '5rem', height: '5rem'}}>
        <span className='visually-hidden'>{intl.formatMessage({id: 'LOADING'})}...</span>
      </Spinner>
    </div>
  )
}

export {Loader}
