import clsx from 'clsx'
import {Spinner} from 'react-bootstrap'

type Props = {
  className?: string
}

const LoadingPage: React.FC<Props> = ({className = ''}) => {
  return (
    <div
      className={clsx('splash-screen position-fixed top-0 left-0 bg-secondary opacity-75', {
        [className]: !!className,
      })}
    >
      <Spinner animation='border' role='status' style={{width: '8rem', height: '8rem'}}>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    </div>
  )
}

export {LoadingPage}
