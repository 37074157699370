export const TIMEZONES = [
  {
    offset: -12,
    label: '(UTC-12:00) International Date Line West',
    value: 'Etc/GMT+12',
  },
  {
    offset: -11,
    label: '(UTC-11:00) Coordinated Universal Time-11',
    value: 'Pacific/Pago_Pago',
  },
  {
    offset: -10,
    label: '(UTC-10:00) Aleutian Islands',
    value: 'US/Aleutian',
  },
  {
    offset: -10,
    label: '(UTC-10:00) Hawaii',
    value: 'US/Hawaii',
  },
  {
    offset: -9.5,
    label: '(UTC-09:30) Marquesas Islands',
    value: 'Pacific/Marquesas',
  },
  {
    offset: -9,
    label: '(UTC-09:00) Alaska',
    value: 'US/Alaska',
  },
  {
    offset: -9,
    label: '(UTC-09:00) Coordinated Universal Time-09',
    value: 'Pacific/Gambier',
  },
  {
    offset: -8,
    label: '(UTC-08:00) Pacific Time (US & Canada)',
    value: 'US/Pacific',
  },
  {
    offset: -8,
    label: '(UTC-08:00) Coordinated Universal Time-08',
    value: 'Pacific/Pitcairn',
  },
  {
    offset: -7,
    label: '(UTC-07:00) Arizona',
    value: 'US/Arizona',
  },
  {
    offset: -7,
    label: '(UTC-07:00) Mountain Time (US & Canada)',
    value: 'US/Mountain',
  },
  {
    offset: -6,
    label: '(UTC-06:00) Central America',
    value: 'Etc/GMT+6',
  },
  {
    offset: -6,
    label: '(UTC-06:00) Central Time (US & Canada)',
    value: 'US/Central',
  },
  {
    offset: -6,
    label: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    value: 'America/Mexico_City',
  },
  {
    offset: -5,
    label: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
    value: 'America/Lima',
  },
  {
    offset: -5,
    label: '(UTC-05:00) Eastern Time (US & Canada)',
    value: 'US/Eastern',
  },
  {
    offset: -4,
    label: '(UTC-04:00) Atlantic Time (Canada)',
    value: 'Canada/Atlantic',
  },
  {
    offset: -4,
    label: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    value: 'America/La_Paz',
  },
  {
    offset: -4,
    label: '(UTC-04:00) Santiago',
    value: 'America/Santiago',
  },
  {
    offset: -3.5,
    label: '(UTC-03:30) Newfoundland',
    value: 'Canada/Newfoundland',
  },
  {
    offset: -3,
    label: '(UTC-03:00) City of Buenos Aires',
    value: 'America/Argentina/Buenos_Aires',
  },
  {
    offset: -3,
    label: '(UTC-03:00) Greenland',
    value: 'America/Godthab',
  },
  {
    offset: -3,
    label: '(UTC-03:00) Montevideo',
    value: 'America/Montevideo',
  },
  {
    offset: -2,
    label: '(UTC-02:00) Coordinated Universal Time-02',
    value: 'Atlantic/South_Georgia',
  },
  {
    offset: -1,
    label: '(UTC-01:00) Azores',
    value: 'Atlantic/Azores',
  },
  {
    offset: -1,
    label: '(UTC-01:00) Cape Verde Is.',
    value: 'Atlantic/Cape_Verde',
  },
  {
    offset: 0,
    label: '(UTC) Coordinated Universal Time',
    value: 'UTC',
  },
  {
    offset: 0,
    label: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
    value: 'Europe/London',
  },
  {
    offset: 0,
    label: '(UTC+00:00) Monrovia, Reykjavik',
    value: 'Africa/Monrovia',
  },
  {
    offset: 1,
    label: '(UTC+01:00) Casablanca',
    value: 'Africa/Casablanca',
  },
  {
    offset: 1,
    label: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    value: 'Europe/Rome',
  },
  {
    offset: 1,
    label: '(UTC+01:00) West Central Africa',
    value: 'Africa/Lagos',
  },
  {
    offset: 2,
    label: '(UTC+02:00) Athens, Bucharest',
    value: 'Europe/Athens',
  },
  {
    offset: 2,
    label: '(UTC+02:00) Cairo',
    value: 'Africa/Cairo',
  },
  {
    offset: 2,
    label: '(UTC+02:00) Damascus',
    value: 'Asia/Damascus',
  },
  {
    offset: 2,
    label: '(UTC+02:00) Jerusalem',
    value: 'Asia/Jerusalem',
  },
  {
    offset: 3,
    label: '(UTC+03:00) Amman',
    value: 'Asia/Amman',
  },
  {
    offset: 3,
    label: '(UTC+03:00) Baghdad',
    value: 'Asia/Baghdad',
  },
  {
    offset: 3.5,
    label: '(UTC+03:30) Tehran',
    value: 'Asia/Tehran',
  },
  {
    offset: 4,
    label: '(UTC+04:00) Abu Dhabi, Muscat',
    value: 'Asia/Muscat',
  },
  {
    offset: 4,
    label: '(UTC+04:00) Tbilisi',
    value: 'Asia/Tbilisi',
  },
  {
    offset: 4.5,
    label: '(UTC+04:30) Kabul',
    value: 'Asia/Kabul',
  },
  {
    offset: 5,
    label: '(UTC+05:00) Ashgabat, Tashkent',
    value: 'Asia/Tashkent',
  },
  {
    offset: 5,
    label: '(UTC+05:00) Islamabad, Karachi',
    value: 'Asia/Karachi',
  },
  {
    offset: 5.5,
    label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    value: 'Asia/Kolkata',
  },
  {
    offset: 5.75,
    label: '(UTC+05:45) Kathmandu',
    value: 'Asia/Katmandu',
  },
  {
    offset: 6,
    label: '(UTC+06:00) Dhaka',
    value: 'Asia/Dhaka',
  },
  {
    offset: 6.5,
    label: '(UTC+06:30) Yangon (Rangoon)',
    value: 'Asia/Rangoon',
  },
  {
    offset: 7,
    label: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    value: 'Asia/Bangkok',
  },
  {
    offset: 7,
    label: '(UTC+07:00) Novosibirsk',
    value: 'Asia/Novosibirsk',
  },
  {
    offset: 8,
    label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    value: 'Asia/Hong_Kong',
  },
  {
    offset: 8,
    label: '(UTC+08:00) Kuala Lumpur, Singapore',
    value: 'Asia/Singapore',
  },
  {
    offset: 8.75,
    label: '(UTC+08:45) Eucla',
    value: 'Australia/Eucla',
  },
  {
    offset: 9,
    label: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    value: 'Asia/Tokyo',
  },
  {
    offset: 9,
    label: '(UTC+09:00) Seoul',
    value: 'Asia/Seoul',
  },
  {
    offset: 9.5,
    label: '(UTC+09:30) Adelaide',
    value: 'Australia/Adelaide',
  },
  {
    offset: 9.5,
    label: '(UTC+09:30) Darwin',
    value: 'Australia/Darwin',
  },
  {
    offset: 10,
    label: '(UTC+10:00) Brisbane',
    value: 'Australia/Brisbane',
  },
  {
    offset: 10,
    label: '(UTC+10:00) Canberra, Melbourne, Sydney',
    value: 'Australia/Melbourne',
  },
  {
    offset: 10.5,
    label: '(UTC+10:30) Lord Howe Island',
    value: 'Australia/Lord_Howe',
  },
  {
    offset: 11,
    label: '(UTC+11:00) Solomon Is., New Caledonia',
    value: 'Pacific/Guadalcanal',
  },
  {
    offset: 11,
    label: '(UTC+11:00) Norfolk Island',
    value: 'Pacific/Norfolk',
  },
  {
    offset: 12,
    label: '(UTC+12:00) Auckland, Wellington',
    value: 'Pacific/Auckland',
  },
  {
    offset: 12,
    label: '(UTC+12:00) Coordinated Universal Time+12',
    value: 'Pacific/Wake',
  },
  {
    offset: 12,
    label: '(UTC+12:00) Fiji',
    value: 'Pacific/Fiji',
  },
  {
    offset: 12.75,
    label: '(UTC+12:45) Chatham Islands',
    value: 'Pacific/Chatham',
  },
  {
    offset: 13,
    label: '(UTC+13:00) Samoa',
    value: 'Pacific/Apia',
  },
  {
    offset: 14,
    label: '(UTC+14:00) Kiritimati Island',
    value: 'Pacific/Kiritimati',
  },
]
