import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import {useAuth} from '../../../../app/modules/auth'
import displayConfig from '../../../../displayconfig.json'
import {SwapperComponent} from '../../../assets/ts/components'
import {allowTestOnly} from '../../../helpers'
import UseRoutesPermission from '../../../hooks/UseRoutesPermission'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {routes} = UseRoutesPermission()

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <>
        <div data-kt-menu-trigger='click' className='menu-item here show menu-accordion'>
          {currentUser && routes?.DASHBOARD?.hasPermission && (
            <AsideMenuItem
              className='mt-5'
              classNameTitle='fs-4'
              title={intl.formatMessage({id: 'DASHBOARD'})}
              icon={'/media/gori/aside/dashboard.svg'}
              to='/dashboard'
            />
          )}

          {currentUser && (routes?.ORDERS?.hasPermission || routes?.BATCHES?.hasPermission) && (
            <AsideMenuItemWithSub
              classNameTitle='fs-4'
              title={intl.formatMessage({id: 'ORDERS'})}
              icon={'/media/gori/aside/orders.svg'}
              subRoute={routes?.ORDERS?.subRoutes}
            >
              {currentUser && routes?.ORDERS?.hasPermission && (
                <AsideMenuItem
                  classNameTitle='fs-6'
                  classNameLink='py-2'
                  title={intl.formatMessage({id: 'ORDERS'})}
                  to='/orders?status=open'
                  arrowIcon
                />
              )}
              {currentUser && routes?.BATCHES?.hasPermission && (
                <AsideMenuItem
                  classNameTitle='fs-6'
                  classNameLink='py-2'
                  title={intl.formatMessage({id: 'BATCHES'})}
                  to='/batches?status=open'
                  arrowIcon
                />
              )}
            </AsideMenuItemWithSub>
          )}

          {currentUser && (routes?.SHIPMENTS?.hasPermission || routes?.CLAIMS?.hasPermission) && (
            <AsideMenuItemWithSub
              classNameTitle='fs-4'
              title={intl.formatMessage({id: 'SHIPMENTS'})}
              icon={'/media/gori/aside/shipments.svg'}
              subRoute={routes?.SHIPMENTS?.subRoutes}
            >
              {routes?.SHIPMENTS?.hasPermission && (
                <AsideMenuItem
                  classNameTitle='fs-6'
                  classNameLink='py-2'
                  title={intl.formatMessage({id: 'SHIPMENTS'})}
                  to='/shipments'
                  arrowIcon
                />
              )}
              {routes?.CLAIMS?.hasPermission && (
                <AsideMenuItem
                  classNameTitle='fs-6'
                  classNameLink='py-2'
                  title={intl.formatMessage({id: 'CLAIMS'})}
                  to='/claims'
                  arrowIcon
                />
              )}
            </AsideMenuItemWithSub>
          )}

          {currentUser &&
            (routes?.REPORTS_TRANSACTIONS?.hasPermission ||
              routes?.REPORTS_SHIPMENT?.hasPermission ||
              routes.REPORTS_TRANSACTIONS?.hasPermission) && (
              <AsideMenuItem
                classNameTitle='fs-4'
                title={intl.formatMessage({id: 'REPORTS'})}
                icon={'/media/gori/aside/logs.svg'}
                to='/reports'
              />
            )}

          {(allowTestOnly(currentUser) ||
            (displayConfig.items.nightWatch &&
              currentUser &&
              routes?.NIGHT_WATCH?.hasPermission)) && (
            <AsideMenuItemWithSub
              classNameTitle='fs-4'
              title={intl.formatMessage({id: 'WARRANTY'})}
              icon={'/media/gori/aside/night_watch.svg'}
              subRoute={routes?.NIGHT_WATCH?.subRoutes}
            >
              <AsideMenuItem
                classNameTitle='fs-6'
                classNameLink='py-2'
                title={intl.formatMessage({id: 'NIGHT_WATCH'})}
                to='/nightwatch'
                arrowIcon
              />
            </AsideMenuItemWithSub>
          )}
          {currentUser && routes?.PAYMENTS?.hasPermission && (
            <AsideMenuItem
              classNameTitle='fs-4'
              title={intl.formatMessage({id: 'DEPOSITS'})}
              icon={'/media/gori/aside/deposit.svg'}
              to='/deposits'
            />
          )}
          {currentUser &&
            (routes.SETTINGS.hasPermission ||
              routes.USERS.hasPermission ||
              routes.SHIPPING_ADDRESSES.hasPermission) && (
              <AsideMenuItem
                classNameTitle='fs-4'
                title={intl.formatMessage({id: 'SETTINGS'})}
                icon={'/media/gori/aside/settings.svg'}
                to='/settings'
              />
            )}

          {routes?.DOCS?.hasPermission && (
            <AsideMenuItem
              classNameTitle='fs-4'
              title={intl.formatMessage({id: 'DOCS'})}
              icon={'/media/gori/aside/docs.svg'}
              to='/docs'
            />
          )}
          {!currentUser && (
            <AsideMenuItem
              classNameTitle='fs-4'
              title={intl.formatMessage({id: 'SUPPORT'})}
              icon={'/media/gori/aside/support.svg'}
              to='/support'
            />
          )}

          {!currentUser && (
            <AsideMenuItem
              classNameTitle='fs-4 fw-bolder'
              title={`${intl.formatMessage({id: 'LOGIN'})} / ${intl.formatMessage({
                id: 'REGISTER',
              })}`}
              icon={'/media/gori/aside/account.svg'}
              to='/auth/login'
              onClick={() => SwapperComponent.removeInstances()}
            />
          )}
        </div>
      </>
    </CSSTransition>
  )
}
