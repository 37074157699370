import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import * as Yup from 'yup'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {Button, InputTextFormik} from '../../../../_gori/partials/widgets'
import AuthService from '../core/_requests'

export function ForgotPassword() {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {infoYup} = UseYupValidate()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)

  const forgotPasswordSchema = Yup.object().shape({
    email: infoYup.email(),
  })

  const initialValues = {
    email: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        setLoading(true)
        const config = {cancelToken: newCancelToken()}
        await AuthService.resetPassword(values, config)
        setHasErrors(false)
      } catch (error: any) {
        if (isCancel(error)) return
        setErrorMsg(error.response.data.error.message)
        setHasErrors(true)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {}, [])

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
        <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'FORGOT_PASSWORD'})} ?</h1>
            <div className='text-gray-400 fw-bold fs-4'>
              {intl.formatMessage({id: 'ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD'})}
            </div>
          </div>

          {hasErrors === true && (
            <div className='mb-lg-10 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                {intl.formatMessage({
                  id: errorMsg,
                })}
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-lg-10 alert alert-success alert-dismissible'>
              <div className='alert-text'>
                {intl.formatMessage({id: 'PASSWORD_RESET_INSTRUCTION_HAS_BEEN_EMAILED'})}
              </div>
            </div>
          )}

          <div className='fv-row mb-10'>
            <InputTextFormik
              labelClassName={'form-label fw-bolder text-gray-900 fs-6'}
              label={intl.formatMessage({id: 'EMAIL'})}
              formik={formik}
              name='email'
              required
            />
          </div>

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <Button
              className='btn btn-lg btn-primary fw-bolder me-4'
              label={intl.formatMessage({id: 'SUBMIT'})}
              loadingText={intl.formatMessage({id: 'SUBMIT'})}
              loading={loading}
              disabled={formik.isSubmitting || !formik.isValid}
              event={formik.submitForm}
            />
            <Link to='/auth/login'>
              <button
                type='button'
                className='btn btn-lg btn-light-primary fw-bolder'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {intl.formatMessage({id: 'CANCEL'})}
              </button>
            </Link>
          </div>
          {/* end::Form group */}
        </div>
      </div>
    </CSSTransition>
  )
}
