import React, {FC, useMemo} from 'react'
import Select from 'react-select'

type Props = {
  event: any
  pageSize: number
}

const PageSize: FC<Props> = ({event, pageSize}) => {
  const pageSizes: any = useMemo(
    () => [
      {
        value: 10,
        label: '10',
      },
      {
        value: 25,
        label: '25',
      },
      {
        value: 50,
        label: '50',
      },
      {
        value: 100,
        label: '100',
      },
      {
        value: 200,
        label: '200',
      },
    ],
    []
  )

  return (
    <div>
      <Select
        isSearchable={false}
        isMulti={false}
        options={pageSizes}
        value={{
          value: pageSize,
          label: pageSize,
        }}
        onChange={(value) => event(value)}
      />
    </div>
  )
}

export default PageSize
