import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import HttpStatusCode from '../../../../app/api/HttpStatusCode'
import {Link} from 'react-router-dom'

type Props = {
  handleClose?: () => void
  response?: any
}

const ValidationErrorModal: React.FC<Props> = ({handleClose, response}) => {
  const intl = useIntl()
  const [title, setTitle] = useState<string>()
  const [arrErrorsOrder, setArrErrorsOrder] = useState<any>([])
  const errors = response?.data?.error

  useEffect(() => {
    if (!errors) return
    setTitle(errors?.message)

    if (response?.status !== HttpStatusCode.UNPROCESSABLE_ENTITY) return
    let arrErrors = [{}]
    errors.fields &&
      Object.entries(errors.fields).forEach(([name, value]: [any, any]) => {
        if (!name.includes('.')) {
          value.forEach((err) => {
            let arrErrorField = err.split('|')
            if (arrErrorField.length === 1) {
              arrErrors.push({id: arrErrorField[0]})
            } else if (arrErrorField.length === 2) {
              arrErrors.push({
                input: _.startCase(arrErrorField[0]),
                id: arrErrorField[1],
              })
            } else {
              arrErrors.push({
                input: _.startCase(arrErrorField[0]),
                id: arrErrorField[1],
                max: arrErrorField[2],
              })
            }
          })
        } else {
          value.forEach((err) => {
            arrErrors.push({noIntl: err})
          })
        }
      })

    setArrErrorsOrder(arrErrors.filter((item) => !_.isEmpty(item)))

    return () => {
      arrErrors = [{}]
      setArrErrorsOrder(undefined)
    }
  }, [errors, response?.status])

  return (
    <Modal
      id='gori_order_modal_error'
      tabIndex={-1}
      aria-hidden='true'
      centered
      show={!_.isEmpty(response)}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'ERRORS'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {response?.status === HttpStatusCode.I_AM_A_TEAPOT && (
            <div>
              <div className='fw-bolder fs-3 text-danger d-flex justify-content-center mb-2'>
                {intl.formatMessage({id: 'TOKEN_EXPIRE'})}
              </div>{' '}
              <span className='fs-4'>
                {intl.formatMessage({
                  id: 'UNABLE_TO_RETRIEVE_INVENTORY_PLEASE_UPDATE_SHIPSTATION_TOKEN',
                })}{' '}
                <Link to='/settings/connections/shipstation-token'>
                  {intl.formatMessage({id: 'HERE'})}
                </Link>
              </span>
            </div>
          )}
          {title && (
            <div className='d-flex justify-content-center fw-bolder fs-3 text-danger mb-5'>
              {title}
            </div>
          )}
          {arrErrorsOrder?.length > 0 &&
            arrErrorsOrder.map((err, index) => (
              <div key={index} className='d-flex'>
                <div className='fw-bolder fs-3 text-danger'>
                  -{' '}
                  {err?.max
                    ? intl.formatMessage(
                        {id: err.id},
                        {
                          input: err.input,
                          max: err.max,
                        }
                      )
                    : err?.input
                    ? intl.formatMessage(
                        {id: err.id},
                        {
                          input: err.input,
                        }
                      )
                    : err?.id
                    ? intl.formatMessage({id: err.id})
                    : err.noIntl}
                </div>
              </div>
            ))}
        </Modal.Body>
      </div>
    </Modal>
  )
}

export {ValidationErrorModal}
