import clsx from 'clsx'
import React, {useContext, useState} from 'react'

export interface PageDisabledContextModel {
  pageDisabled?: boolean
  setPageDisabled: (_isDisabled: boolean) => void
}

const PageDisabledContext = React.createContext<PageDisabledContextModel>({
  setPageDisabled: (_isDisabled: boolean) => {},
})

const PageDisabledProvider: React.FC = ({children}) => {
  const [pageDisabled, setPageDisabled] = useState(false)

  const value: PageDisabledContextModel = {
    pageDisabled,
    setPageDisabled,
  }
  return (
    <PageDisabledContext.Provider value={value}>
      <div
        className={clsx('d-flex flex-column flex-column-fluid', {'cursor-no-drop': pageDisabled})}
      >
        <div className={clsx('d-flex flex-column flex-column-fluid', {'pe-none': pageDisabled})}>
          {children}
        </div>
      </div>
    </PageDisabledContext.Provider>
  )
}

function useDisabled() {
  return useContext(PageDisabledContext)
}

export {PageDisabledProvider, useDisabled}
