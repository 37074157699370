import React from 'react'
import {convertCurrency} from '../../../helpers'
import {isEmpty} from 'lodash'

type Props = {
  service: any
  cost?: any
}

const CarrierBadge: React.FC<Props> = ({service, cost}) => {
  return (
    <div className='d-flex align-items-center align-middle justify-content-center'>
      <div className='d-flex flex-column w-75px'>
        {!isEmpty(service) && (
          <div className='symbol symbol-circle symbol-25px overflow-hidden d-flex justify-content-center'>
            <div className='symbol-label'>
              <img src={service?.logo} alt='logo' className='w-100' />
            </div>
          </div>
        )}

        <div className='d-flex justify-content-center text-center'>
          <span className='mb-1' style={{fontSize: '12px'}}>
            {service?.display_name}
          </span>
        </div>
        <div className='d-flex justify-content-center'>
          <span className='text-gray-800'>{cost && convertCurrency(cost)}</span>
        </div>
      </div>
    </div>
  )
}

export {CarrierBadge}
