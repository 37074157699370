import clsx from 'clsx'
import {useFormik} from 'formik'
import {isEmpty, isUndefined} from 'lodash'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import * as Yup from 'yup'
import {useAuth} from '..'
import {PasswordMeterComponent} from '../../../../_gori/assets/ts/components'
import {StorageHelpers, hasNoSubdomain} from '../../../../_gori/helpers'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {Button, SelectFormik} from '../../../../_gori/partials/widgets'
import {InputPassword} from '../../../../_gori/partials/widgets/form/InputPassword'
import AuthService from '../core/_requests'
import {Link} from 'react-router-dom'

const baseDomain = process.env.REACT_APP_DOMAIN

export function Login() {
  const protocal = document.location.protocol
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup} = UseYupValidate()
  const {saveAuth} = useAuth()
  const valueUsername = useRef('')
  const [loading, setLoading] = useState<{
    login: boolean
    resentEmail: boolean
    getSubdomain: boolean
    checkSubdomainExist: boolean
  }>({
    login: false,
    resentEmail: false,
    getSubdomain: false,
    checkSubdomainExist: false,
  })
  const [checkErrorLogin, setCheckErrorLogin] = useState(false)
  const [slugs, setSlugs] = useState<any>([])
  const [slugInit, setSlugInit] = useState<boolean>(false)
  const [statusForm, setStatusForm] = useState<
    {status: 'success' | 'error'; message: string} | undefined
  >(undefined)

  const loginSchema = Yup.object().shape({
    username: stringYup(50, 'EMAIL/USERNAME'),
    password: stringYup(50, 'PASSWORD'),
    slug: stringYup(50, 'SUBDOMAIN'),
  })

  const initialValues = {
    username: '',
    password: '',
    slug: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setStatusForm(undefined)
      setCheckErrorLogin(false)
      try {
        setLoading((prev) => ({...prev, login: true}))
        const config = {cancelToken: newCancelToken()}
        const login = await AuthService.login(values, config)
        if (login) {
          let urlRedirect = `${protocal}//${values?.slug}.${baseDomain}/login-page`
          const expirationDateUserInfo = new Date()
          expirationDateUserInfo.setTime(expirationDateUserInfo.getTime() + 60 * 1000)
          StorageHelpers.setItemCookies('userInfo', values, {
            path: '/login-page',
            expires: expirationDateUserInfo,
            domain: `.${baseDomain?.split(':')?.[0]}`,
          })
          document.location.replace(urlRedirect)
        }
      } catch (error: any) {
        if (isCancel(error)) return
        saveAuth(undefined)
        let errorMessage = error?.response?.data?.message
        setStatusForm({
          status: 'error',
          message: intl.formatMessage({id: errorMessage}),
        })
        if (errorMessage === 'TOKEN_NOT_CONFIRMED') {
          setCheckErrorLogin(true)
        }
      } finally {
        setLoading((prev) => ({...prev, login: false}))
      }
    },
  })

  useEffect(() => {
    const host = document.location.host?.split(':')?.[0]
    const domain = baseDomain?.split(':')?.[0]
    const subDomain = host.split(`.${domain}`).filter((item) => item !== domain)?.[0]

    if (subDomain) {
      setSlugInit(true)
      formik.setFieldValue('slug', subDomain)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resendVerificationEmail = async () => {
    setStatusForm(undefined)

    try {
      setLoading((prev) => ({...prev, resentEmail: true}))
      const config = {cancelToken: newCancelToken()}

      let payload = {
        email: formik.values?.username,
        slug: formik.values?.slug,
      }
      const res = await AuthService.resendVerificationEmail(payload, config)
      if (res) {
        setStatusForm({
          status: 'success',
          message: intl.formatMessage({id: 'ENTER_VERIFICATION_ACCOUNT_SENT'}),
        })
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setStatusForm({
        status: 'error',
        message: intl.formatMessage({id: 'SEND_VERIFICATION_ACCOUNT_ERROR'}),
      })
    } finally {
      setLoading((prev) => ({...prev, resentEmail: false}))
    }
  }

  const getListSlug = useCallback(async () => {
    if (slugInit || !formik?.values.username) return
    setSlugs([])
    formik.setFieldValue('slug', '')
    setStatusForm(undefined)

    try {
      setLoading((prev) => ({...prev, getSubdomain: true}))
      let payload = {
        email: formik.values?.username,
      }
      const config = {cancelToken: newCancelToken()}
      const res = await AuthService.getListSlugs(payload, config)
      if (res?.slug.length > 0) {
        setSlugs(res.slug)
        formik.setFieldValue('slug', res?.slug?.[0] || '')
      } else {
        setStatusForm({
          status: 'error',
          message: intl.formatMessage({id: 'INVALID_CREDENTIALS'}),
        })
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setSlugs([])
      setStatusForm({
        status: 'error',
        message: intl.formatMessage({id: 'INVALID_CREDENTIALS'}),
      })
    } finally {
      setLoading((prev) => ({...prev, getSubdomain: false}))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, newCancelToken, isCancel, formik?.values.username, formik?.values.password])

  const optionsSlugs = useMemo(() => {
    return slugs?.length > 0
      ? Object.entries(slugs).map(([key, value]: [any, any]) => ({
          value: value,
          label: value,
        }))
      : []
  }, [slugs])

  const checkCompanySubDomainExist = useCallback(async () => {
    setLoading((prev) => ({...prev, checkSubdomainExist: true}))
    try {
      await AuthService.checkSubdomainValid()
    } catch (error) {
      if (!hasNoSubdomain()) {
        const baseDomain = process.env.REACT_APP_DOMAIN
        const protocal = document.location.protocol
        document.location.replace(protocal + '//' + baseDomain + '/error/404')
      }
    } finally {
      setLoading((prev) => ({...prev, checkSubdomainExist: false}))
    }
  }, [])

  useEffect(() => {
    if (slugInit) {
      checkCompanySubDomainExist()
    }
    PasswordMeterComponent.bootstrap()
  }, [checkCompanySubDomainExist, slugInit])

  useEffect(() => {
    if (slugs.length > 0) {
      setStatusForm(undefined)
      setCheckErrorLogin(false)
    }
  }, [formik.values, slugs.length])

  const getVersion = useCallback(async () => {
    setLoading((prev) => ({...prev, checkSubdomainExist: true}))
    try {
      const response = await AuthService.getVersion()
      if (String(response?.app_version) !== String(process.env.REACT_APP_VERSION)) {
        // Clearing localStorage
        localStorage.clear()

        // Clearing cookies
        document.cookie.split(';').forEach((cookie) => {
          const [name] = cookie.split('=')
          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
        })

        // Reloading the page
        window.location.href = window.location.href + '?version=' + Date.now()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading((prev) => ({...prev, checkSubdomainExist: false}))
    }
  }, [])

  useEffect(() => {
    getVersion()
  }, [getVersion])

  const renderLoadingText = useMemo(() => {
    if (loading.checkSubdomainExist) {
      return ''
    } else if (loading.getSubdomain) {
      return intl.formatMessage({id: 'GETTING_SUBDOMAIN'})
    } else {
      return intl.formatMessage({id: 'LOGGING_IN'})
    }
  }, [intl, loading.checkSubdomainExist, loading.getSubdomain])

  // BEGIN: Event auto fill browser
  useEffect(() => {
    if (
      !slugInit &&
      document?.querySelectorAll('input:-webkit-autofill').length > 1 &&
      formik.values.username.trim() !== valueUsername.current
    ) {
      const objVal: {
        username: string
        password: string
        slug: string
      } = {username: '', password: '', slug: ''}

      document?.querySelectorAll('input:-webkit-autofill').forEach((input: any) => {
        if (input?.name === 'username') {
          objVal.username = input?.value || formik.values.username
        } else if (input?.name === 'password') {
          objVal.password = input?.value || formik.values.password
        }
      })
      objVal.slug = ''
      formik.setValues(objVal)
      getListSlug()
      valueUsername.current = formik.values.username?.trim()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])
  // END: Event auto fill browser

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
            <div className='text-center mb-10'>
              <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'SIGN_IN'})}</h1>
              {!slugInit && (
                <div className='text-gray-400 fw-bold fs-4'>
                  {intl.formatMessage({id: 'NEW_HERE'})}?{' '}
                  <Link to='/auth/registration' className='link-primary fw-bolder'>
                    {intl.formatMessage({id: 'CREATE_AN_ACCOUNT'})}
                  </Link>
                </div>
              )}
            </div>

            {!isUndefined(statusForm) && (
              <div
                className={clsx('mb-lg-15 alert', {
                  'alert-success': statusForm.status === 'success',
                  'alert-danger': statusForm.status === 'error',
                })}
              >
                <div className='alert-text font-weight-bold'>{statusForm.message}</div>
              </div>
            )}
            <div className='fv-row mb-10 fv-plugins-icon-container'>
              <label className={`form-label form-label fw-bolder text-dark fs-6 required`}>
                {intl.formatMessage({id: 'EMAIL'})}
              </label>
              <div>
                <div className='input-group'>
                  <input
                    autoFocus
                    type='text'
                    autoComplete='off'
                    className={clsx(
                      `form-control form-control-lg`,
                      {
                        'is-invalid': formik.touched.username && formik.errors.username,
                      },
                      {
                        'is-valid':
                          formik.touched.username &&
                          !formik.errors.username &&
                          formik.values.username?.trim(),
                      }
                    )}
                    {...formik.getFieldProps('username')}
                    onBlur={() => {
                      formik.setFieldTouched('username', true)
                      if (
                        !slugInit &&
                        formik.values.username?.trim() &&
                        valueUsername.current !== formik.values.username?.trim()
                      ) {
                        getListSlug()
                        valueUsername.current = formik.values.username?.trim()
                      }
                    }}
                  />
                </div>
                {formik.touched.username && formik.errors.username && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>
                      <span role='alert'>{formik.errors.username}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='fv-row mb-10 fv-plugins-icon-container'>
              <div className='d-flex flex-stack mb-2'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>
                  {intl.formatMessage({id: 'PASSWORD'})}
                </label>
                <a
                  href={`${protocal}//${baseDomain}/auth/forgot-password`}
                  tabIndex={1000}
                  className='link-primary fs-6 fw-bolder'
                >
                  {intl.formatMessage({id: 'FORGOT_PASSWORD'})} ?
                </a>
              </div>
              <InputPassword
                size='lg'
                openHighlight={false}
                formik={formik}
                name={'password'}
                required
              />
            </div>

            {!slugInit && (
              <div className='fv-row mb-10 fv-plugins-icon-container'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>
                  {intl.formatMessage({id: 'SUBDOMAIN'})}
                </label>
                <SelectFormik
                  emptyDefault={false}
                  className='fs-6 bg-white text-dark subdomain'
                  options={optionsSlugs}
                  name='slug'
                  formik={formik}
                  placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                  required
                  styleInput={{background: '#ffffff', height: 'auto', padding: '4px'}}
                  onFocus={() => {
                    if (isEmpty(optionsSlugs)) {
                      getListSlug()
                    }
                  }}
                />
              </div>
            )}
            <div className='text-center'>
              <Button
                className='btn btn-lg btn-primary w-100 mb-5'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={renderLoadingText}
                loading={loading.checkSubdomainExist || loading.getSubdomain || loading.login}
                disabled={loading.checkSubdomainExist || loading.getSubdomain || loading.login}
                event={formik.submitForm}
              />
            </div>
            {checkErrorLogin && (
              <div
                onClick={() => {
                  if (!loading.resentEmail) {
                    resendVerificationEmail()
                  }
                }}
                tabIndex={1000}
                className='d-flex link-danger fs-6 fw-bolder text-decoration-underline cursor-pointer'
              >
                {intl.formatMessage({id: 'RESEND_VERIFICATION_EMAIL'})}
                {loading.resentEmail && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </CSSTransition>
    </>
  )
}
