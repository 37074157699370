import _ from 'lodash'

export const blockPopUp = (url = '', target = '_blank') => {
  if (_.isEmpty(url)) {
    return false
  }

  const newTab = window.open(getClickableLink(url), target)
  return newTab === null
}

export const getClickableLink = (link) => {
  if (!link) {
    return ''
  }
  return link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`
}
