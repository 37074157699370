import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_gori/helpers'
import {configureUI} from '../../../../_gori/constants'

export function Header() {
  const intl = useIntl()
  const BASE_DOMAIN = process.env.REACT_APP_DOMAIN
  return (
    <div className='fixed-top bg-white border-bottom pt-5 pb-1'>
      <header className='container d-flex flex-wrap justify-content-center mb-4'>
        <div className='d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none'>
          <Link to='/landing'>
            <img
              alt='Logo'
              src={
                configureUI.urlLogo.tabletAside
                  ? configureUI.urlLogo.tabletAside
                  : toAbsoluteUrl('/media/logos/shippingitnow-3.png')
              }
              // className='h-25px'
            />
          </Link>
        </div>
        <ul className='nav nav-pills'>
          <li className='nav-item'>
            <a
              className='btn btn-outline btn-active-light-success rounded-pill px-8'
              href={document.location.protocol + '//' + BASE_DOMAIN + '/auth/registration'}
              style={{borderColor: '#16BAA8', color: '#16BAA8'}}
            >
              {intl.formatMessage({id: 'REGISTER'})}
            </a>
          </li>
          <li className='nav-item'>
            <Link
              to='/auth'
              className='btn btn-outline btn-active-light-success rounded-pill px-8'
              style={{borderColor: '#16BAA8', color: '#16BAA8'}}
            >
              {intl.formatMessage({id: 'LOG_IN'})}
            </Link>
          </li>
        </ul>
      </header>
    </div>
  )
}
