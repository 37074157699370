// BEGIN: Logo
const urlLogo = {
  favicon: 'https://dummyimage.com/184x50/ffffff/14b8a6.png&text=Custom+Company',
  aside: 'https://dummyimage.com/184x50/ffffff/14b8a6.png&text=Custom+Company',
  tabletAside: 'https://dummyimage.com/184x50/ffffff/14b8a6.png&text=Custom+Company',
  auth: 'https://dummyimage.com/184x50/ffffff/14b8a6.png&text=Custom+Company',
}
// END: Logo

export const configureUI = {
  urlLogo,
}
