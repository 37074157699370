import React from 'react'
import _uniqueId from 'lodash/uniqueId'
import {KTSVG} from '../../../helpers'

type Props = {
  title: string
  onIcon?: any
  offIcon?: any
  list: any
  unique?: any
}

const Accordion: React.FC<Props> = ({title, onIcon, offIcon, list, unique}) => {
  const uuid = _uniqueId(`accordion-${unique}-`)
  return (
    <>
      <div
        className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
        data-bs-toggle='collapse'
        data-bs-target={`#${uuid}`}
      >
        <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
          {onIcon ?? (
            <KTSVG
              path='/media/gori/docs/toggle_on.svg'
              className='toggle-on svg-icon-primary svg-icon-1'
            />
          )}
          {offIcon ?? (
            <KTSVG path='/media/gori/docs/toggle_off.svg' className='toggle-off svg-icon-1' />
          )}
        </div>
        <h4 className='text-gray-700 fw-bolder cursor-pointer mb-0'>{title}</h4>
      </div>

      <div id={uuid} className='collapse fs-6 ms-1'>
        {list &&
          list.map((child: any, index: number) => (
            <div key={index} className='mb-4 text-gray-600 fw-bold fs-6 ps-10'>
              {child}
            </div>
          ))}
      </div>
    </>
  )
}

export {Accordion}
