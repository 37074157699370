export const CLAIMS_STATUS = {
  UNSUBMITTED: 'unsubmitted',
  DENIED: 'denied',
}

export const VALIDATE_FILE = {
  SIZE: 8388608,
  EXTENSION_ALLOW: ['jpg', 'jpeg', 'png', 'pdf'],
  EXTENSION_OTHER_IMAGE: ['pdf'],
}

export const OPTION_CLAIMS_REASON = [
  {label: 'Lost', value: 'lost'},
  {label: 'Damage', value: 'damage'},
]

export const OPTION_CLAIMS_APPEAL_REASON = [
  {label: 'I am submitting additional information/evidence', value: 'evidence'},
  {label: 'The payment is insufficient', value: 'insufficient'},
  {label: 'Other', value: 'other'},
]

export const OPTION_ITEM_TYPE = [
  {label: 'Cash/Certificate', value: 'cash_certificate'},
  {label: 'Jewelry', value: 'jewelry'},
  {label: 'Clothing/Home Products', value: 'clothing_home_products'},
  {label: 'Arts/Crafts', value: 'arts_crafts'},
  {label: 'Media, Music Video', value: 'media_music_video'},
  {label: 'Electronics', value: 'electronics'},
  {label: 'Computers', value: 'computers'},
  {label: 'Collectibles', value: 'collectibles'},
  {label: 'Sports Equipment', value: 'sports_equipment'},
  {label: 'Liquor/Wine', value: 'liquor_wine'},
  {label: 'Animals', value: 'animals'},
  {label: 'Document Reconstruction', value: 'document_reconstruction'},
  {label: 'Hazardous Material', value: 'hazardous_material'},
  {label: 'Event Tickets', value: 'event_tickets'},
  {label: 'Other', value: 'other'},
]

export const ClaimsConfig = {
  SHOW_ADD_NEW_ITEM: 5,
  SHOW_DELETE_ITEM: 1,
  ITEM_TYPE_OTHER: 'other',
  CARRIER: 'usps',
}
