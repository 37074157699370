import React, {useEffect} from 'react'
import {useLocation} from 'react-router'
import {DrawerComponent} from '../../assets/ts/components'
import moment from 'moment-timezone'
import {useAuth} from '../../../app/modules/auth'

const Content: React.FC = ({children}) => {
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  const {currentUser} = useAuth()

  useEffect(() => {
    moment.tz.setDefault(currentUser?.user_profiles?.general?.timezone?.value)
  }, [currentUser])
  return (
    <div
      id='gori_content_container'
      className='mx-10 mx-xxl-10 d-flex flex-column flex-column-fluid'
    >
      {children}
    </div>
  )
}

export {Content}
