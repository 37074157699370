import clsx from 'clsx'
import {getIn} from 'formik'
import {size} from 'lodash'
import {FC, useEffect} from 'react'

type Props = {
  onChange?: any
  className?: string
  classNameSelect?: string
  labelClassName?: string | null
  label?: string | null
  required?: boolean
  options: Array<any>
  formik: any
  name: string
  placeholder?: string | null
  checkFormik?: boolean
}

const Select: FC<Props> = ({
  onChange,
  className,
  classNameSelect,
  labelClassName = null,
  label = null,
  options,
  required = false,
  formik,
  name,
  placeholder = null,
  checkFormik = true,
}) => {
  let fieldProps = formik.getFieldProps(name)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)

  useEffect(() => {
    const hasValue = options.filter((item) => item.value === formik.getFieldProps(name).value)
    if (size(hasValue) === 0) {
      formik.setFieldValue(name, options[0].value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.name])

  return (
    <>
      {label || labelClassName ? (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      ) : (
        ''
      )}
      <div className={className}>
        <div className='input-group'>
          <select
            className={clsx(
              `${classNameSelect} form-select`,
              {'is-invalid': formikTouched && formikErrors && checkFormik},
              {'is-valid': formikTouched && !formikErrors && fieldProps.value && checkFormik}
            )}
            placeholder={placeholder}
            {...fieldProps}
            {...(onChange ? {onChange: onChange} : {})}
          >
            {options &&
              options.map((option, index) => (
                <option key={index} value={option.value} disabled={option.disabled}>
                  {option.label}
                </option>
              ))}
          </select>
        </div>
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {Select}
