import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {toAbsoluteUrl} from '../../../_gori/helpers'
import {Footer} from './components/Footer'
import {Header} from './components/Header'

const baseDomain = process.env.REACT_APP_DOMAIN

export function LandingPage() {
  const intl = useIntl()

  useEffect(() => {
    const protocol = document.location.protocol
    const host = document.location.host?.split(':')?.[0]
    const domain = baseDomain?.split(':')?.[0]
    const subDomain = host.split(`.${domain}`).filter((item) => item !== domain)?.[0]
    if (subDomain) {
      let urlRedirect = `${protocol}//${baseDomain}/landing`
      document.location.replace(urlRedirect)
    }
  }, [])

  return (
    <>
      <Header />
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <>
          {/* Main */}
          <div className='bg-white pb-20 mt-10'>
            {/* tittle */}
            <div className='container d-flex align-items-center justify-content-between mt-20 mb-20'>
              <div className='mt-20 mb-10' style={{zIndex: '1'}}>
                <div className='mb-3 mt-10 fw-bolder lh-sm' style={{fontSize: '80px'}}>
                  {intl.formatMessage({id: 'MULTI_CARRIER'})} <br />{' '}
                  {intl.formatMessage({id: 'SHIPPING_RATE_SOLUTION'})}
                </div>
                <p className='fs-4 fw-bold'>
                  {intl.formatMessage({
                    id: 'WE_OFFER_SHIPPING_RATES_FOR_DOMESTIC_AND_INTERNATIONAL_IMPORT_AND_EXPORT_SERVICES_TO_OVER_180_COUNTRIES',
                  })}
                </p>
              </div>
              <img
                src={toAbsoluteUrl('/media/gori/lending/main-bg.png')}
                className='d-block w-300px'
                alt=''
              />
            </div>

            {/* carousel */}
            <div className='container justify-content-center align-items-center d-flex mb-20'>
              <div
                id='carouselExampleIndicators'
                className='carousel slide'
                data-bs-ride='carousel'
                data-bs-interval='3000'
              >
                <ol className='carousel-indicators'>
                  <li
                    data-bs-target='#carouselExampleIndicators'
                    data-bs-slide-to='0'
                    className='active'
                    aria-current='true'
                    aria-label='Slide 1'
                  ></li>
                  <li
                    data-bs-target='#carouselExampleIndicators'
                    data-bs-slide-to='1'
                    aria-label='Slide 2'
                  ></li>
                  <li
                    data-bs-target='#carouselExampleIndicators'
                    data-bs-slide-to='2'
                    aria-label='Slide 3'
                  ></li>
                </ol>
                <div className='carousel-inner'>
                  <div className='carousel-item position-relative active'>
                    <img
                      alt=''
                      src={toAbsoluteUrl('/media/gori/lending/main1.png')}
                      className='d-block w-100'
                    />
                    <div className='position-absolute top-50 start-50 translate-middle display-5 text-white text-center lh-lg w-100'>
                      {intl.formatMessage({id: 'HELPING_BUSINESSES_IDENTIFY'})} <br />{' '}
                      {intl.formatMessage({id: 'OPPORTUNITIES_AND_POTENTIAL_RISKS'})}
                    </div>
                  </div>
                  <div className='carousel-item position-relative'>
                    <img
                      alt=''
                      src={toAbsoluteUrl('/media/gori/lending/main2.png')}
                      className='d-block w-100'
                    />
                    <div className='position-absolute top-50 start-50 translate-middle display-5 text-white text-center lh-lg w-100'>
                      {intl.formatMessage({id: 'ONE_PLACE_TO_MANAGE'})}
                      <br />
                      {intl.formatMessage({id: 'ALL_YOUR_SHIPMENTS_AND_TASKS'})}
                    </div>
                  </div>
                  <div className='carousel-item position-relative'>
                    <img
                      alt=''
                      src={toAbsoluteUrl('/media/gori/lending/main3.png')}
                      className='d-block w-100'
                    />
                    <div className='position-absolute top-50 start-50 translate-middle display-6 text-white text-center lh-lg w-100'>
                      {intl.formatMessage({id: 'OUR_SHIPPING_WARRANTY_OFFERS_COMPREHENSIVE'})}{' '}
                      <br /> {intl.formatMessage({id: 'COVERAGE_AGAINST_DAMAGES'})}
                      {intl.formatMessage({id: 'DURING_TRANSIT'})}
                    </div>
                  </div>
                </div>
                <button
                  className='carousel-control-prev'
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide='prev'
                >
                  <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                  <span className='visually-hidden'>{intl.formatMessage({id: 'PREVIOUS'})}</span>
                </button>
                <button
                  className='carousel-control-next'
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide='next'
                >
                  <span className='carousel-control-next-icon' aria-hidden='true'></span>
                  <span className='visually-hidden'>{intl.formatMessage({id: 'NEXT'})}</span>
                </button>
              </div>
            </div>
          </div>

          {/* the way shipping it now works */}
          <div>
            <div className='container justify-content-center d-flex'>
              <div className='py-20 mb-10'>
                <div className='text-center display-5 fw-bolder mb-5 pt-15 pb-20'>
                  {intl.formatMessage({id: 'THE_WAY_SHIPPING_IT_NOW_WORKS'})}
                </div>
                <div className='row'>
                  <div className='col-3 px-7'>
                    <img
                      src={toAbsoluteUrl('/media/gori/lending/ai-data.png')}
                      className='d-block w-100'
                      alt=''
                    />
                    <p className='fs-6'>
                      {intl.formatMessage({
                        id: 'OUR_SMART_RATE_TOOL_ENABLES_CLIENTS_TO_OPTIMIZE_THEIR_SHIPMENTS_AND_SAVE_MONEY',
                      })}
                      {intl.formatMessage({
                        id: 'SHIP_FASTER_AND_MAKE_SMARTER_SHIPPING_DECISIONS_BASED_ON_THEIR_BUSINESS_NEEDS',
                      })}
                    </p>
                  </div>
                  <div className='col-3 px-7'>
                    <img
                      src={toAbsoluteUrl('/media/gori/lending/label-creation.png')}
                      className='d-block w-100'
                      alt=''
                    />
                    <p className='fs-6'>
                      {intl.formatMessage({
                        id: 'SIMPLY_ENTER_IN_YOUR_PACKAGE_LEVEL_DETAIL_TO_RATE_SHOP_BETWEEN_CARRIERS_AND',
                      })}
                      {intl.formatMessage({id: 'SERVICE_LEVELS_FOR_THE_RIGHT_PRICE_AND_SPEED'})}
                    </p>
                  </div>
                  <div className='col-3 px-7'>
                    <img
                      src={toAbsoluteUrl('/media/gori/lending/address-verification.png')}
                      className='d-block w-100'
                      alt=''
                    />
                    <p className='fs-6'>
                      {intl.formatMessage({
                        id: 'OUR_ADDRESS_VERIFICATION_API_PROVIDES_YOUR_CUSTOMERS_WITH_THE_ULTIMATE_PURCHASE_EXPERIENCE_BY_ENHANCING_DELIVERABILITY',
                      })}
                    </p>
                  </div>
                  <div className='col-3 px-7'>
                    <img
                      src={toAbsoluteUrl('/media/gori/lending/warranty.png')}
                      className='d-block w-100'
                      alt=''
                    />
                    <p className='fs-6'>
                      {intl.formatMessage({
                        id: 'WHEN_PACKAGES_ARE_LOST_DAMAGED_OR_STOLEN_GORI_WORKS_DIRECTLY_WITH_CUSTOMERS_TO_ORDER_A_REPLACEMENT',
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* zigzag */}
          <div className='bg-white py-20'>
            <div className='container '>
              <div className='row pb-20 my-20 py-10'>
                <div className='d-flex justify-content-between flex-column col-lg-5'>
                  <div className='pt-3'>
                    <div className='display-5 fw-bolder pb-2'>
                      {intl.formatMessage({id: 'DASHBOARD_TO_DISPLAY'})}
                    </div>
                    <div
                      className='display-5 fw-bolder'
                      style={{
                        borderBottom: '16px solid rgba(137, 80, 252, 0.5)',
                        display: 'inline-block',
                        height: '1.2em',
                      }}
                    >
                      {intl.formatMessage({id: 'YOUR_SHIPMENT_DATA'})}
                    </div>
                  </div>
                  <p className='fs-1 text-gray-700 fw-light'>
                    {intl.formatMessage({
                      id: 'IT_CAN_HELP_UNCOVER_PATTERNS_TRENDS_AND_RELATIONSHIPS_THAT_MIGHT_NOT_BE_APPARENT_FROM_A_SINGLE_DATA_SOURCE_LEADING_TO_BETTER_ANALYSIS_AND_DECISION_MAKING',
                    })}
                  </p>
                </div>
                <div className='d-flex justify-content-end col-lg-7'>
                  <img
                    src={toAbsoluteUrl('/media/gori/lending/sn1.png')}
                    className='d-block w-75'
                    alt=''
                  />
                </div>
              </div>
              <div className='row pb-20 my-20 py-10'>
                <div className='d-flex justify-content-between flex-column col-lg-5 order-lg-2'>
                  <div className='pt-3'>
                    <div className='display-5 fw-bolder pb-2'>
                      {intl.formatMessage({id: 'ALL_IN_ONE_SHIPMENT'})}
                    </div>
                    <span
                      className='display-5 fw-bolder'
                      style={{
                        borderBottom: '16px solid rgba(137, 80, 252, 0.5)',
                        display: 'inline-block',
                        height: '1.2em',
                      }}
                    >
                      {intl.formatMessage({id: 'MANAGEMENT_PLATFORM'})}
                    </span>
                  </div>
                  <p className='fs-1 text-gray-700 fw-light'>
                    {intl.formatMessage({
                      id: 'OUR_SYSTEM_ENABLES_YOU_TO_QUICKLY_AND_EASILY_CHECK_THE_DELIVERY_STATUS_OF_YOUR_STORES_AND_MANAGE_THEM_WITH_EASE',
                    })}
                  </p>
                </div>
                <div className='d-flex justify-content-start col-lg-7 order-lg-1'>
                  <img
                    src={toAbsoluteUrl('/media/gori/lending/sn2.png')}
                    className='d-block w-100'
                    alt=''
                  />
                </div>
              </div>
              <div className='row pb-20 my-20 py-10'>
                <div className='d-flex justify-content-between flex-column col-lg-5'>
                  <div className='pt-3'>
                    <div
                      className='display-5 fw-bolder pb-2'
                      style={{
                        borderBottom: '16px solid rgba(137, 80, 252, 0.5)',
                        display: 'inline-block',
                        height: '1.2em',
                      }}
                    >
                      {intl.formatMessage({id: 'MULTICARRIER_DELIVERY'})}
                    </div>
                    <div className='display-5 fw-bolder'>
                      {intl.formatMessage({id: 'MANAGEMENT'})}
                    </div>
                  </div>
                  <p className='fs-1 text-gray-700 fw-light'>
                    {intl.formatMessage({
                      id: 'DELIVERY_INTEGRATION_IS_A_SYSTEM_THAT_INTEGRATES_PRODUCTS_SENT_BY_MULTIPLE_TRANSPORTATION_AND_COURIER_COMPANIES_INTO_ONE_PLATFORM_FOR_TRACKING_AND_MANAGEMENT',
                    })}
                  </p>
                </div>
                <div className='d-flex justify-content-end col-lg-7'>
                  <img
                    src={toAbsoluteUrl('/media/gori/lending/sn3.png')}
                    className='d-block w-100'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Shipping Discount */}
          <div className='py-20'>
            <div className='container text-center my-10'>
              <div className='display-5 fw-bolder lh-lg mb-1'>
                {intl.formatMessage({id: 'SHIPPING_DISCOUNT'})}
              </div>
              <p className='fs-1 text-gray-700 fw-light mb-15'>
                {intl.formatMessage({
                  id: 'SHIPPING_COSTS_ARE_THE_#1_REASON_FOR_SHOPPING_CART_ABANDONMENT_IF_YOU_WANT_TO_COMPETE_IN_THE_ECOMMERCE_SPACE_YOU_ARE_GOING_TO_NEED_TO_HAVE_THE_BEST_SHIPPING_RATES_POSSIBLE',
                })}
              </p>
              <Link
                to='/auth'
                className='btn btn-lg btn-outline btn-active-warning rounded-pill px-9'
                style={{backgroundColor: '#16BAA8', color: 'white'}}
              >
                {intl.formatMessage({id: 'LOG_IN'})}
              </Link>
            </div>
          </div>
        </>
      </CSSTransition>
      <Footer />
    </>
  )
}
