import {CSSTransition} from 'react-transition-group'
import {setAuth, useAuth} from '../../../app/modules/auth'
import {useMeter} from '../../../_gori/helpers/components/MeterProvider'
import {useCallback, useEffect} from 'react'
import AuthService from '../../modules/auth/core/_requests'
import {useNavigate, useSearchParams} from 'react-router-dom'
import UserService from '../../modules/users/core/_requests'
import {LoadingPage} from '../../../_gori/helpers/components/LoadingPage'
import {StorageHelpers} from '../../../_gori/helpers'

const CURRENT_COMPANY_STORAGE_KEY =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE_KEY || 'CURRENT_COMPANY_STORAGE_KEY'
const PERMISSIONS_STORAGE_KEY =
  process.env.REACT_APP_PERMISSIONS_STORAGE_KEY || 'PERMISSIONS_STORAGE_KEY'
const AUTH_STORAGE_KEY = process.env.REACT_APP_AUTH_LOCAL_STORAGE_KEY || 'AUTH_STORAGE_KEY'
const STATES_GLOBAL_STORAGE_KEY =
  process.env.REACT_APP_STATES_GLOBAL_STORAGE_KEY || 'STATES_GLOBAL_STORAGE_KEY'
const CURRENT_COMPANY_STORAGE =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE || 'CURRENT_COMPANY_STORAGE'

export function RefreshTokenPage() {
  const navigate = useNavigate()
  const {setCurrentCompany, setLoadingSwitch, setCurrentUser} = useAuth()
  const {setMeterChange} = useMeter()
  const [searchParams] = useSearchParams()

  const getCompany = useCallback(
    (companies: any) => {
      const slug = searchParams.get('slug')
      return companies.find((item) => {
        if (slug === item.slug) {
          return item
        }
        return null
      })
    },
    [searchParams]
  )

  const handleRefreshToken = useCallback(
    async () => {
      StorageHelpers.removeItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY)
      StorageHelpers.removeItemLocalStorage(PERMISSIONS_STORAGE_KEY)
      StorageHelpers.removeItemLocalStorage(AUTH_STORAGE_KEY)
      StorageHelpers.removeItemLocalStorage(STATES_GLOBAL_STORAGE_KEY)
      const callRefreshToken = async () => {
        try {
          setLoadingSwitch(true)
          const res = await AuthService.refreshToken({refresh_token: searchParams.get('token')})
          if (res) {
            setAuth(res)
            const userAuth = await UserService.getUser()
            const newCompany = await getCompany(userAuth.companies)
            const saveCache = await AuthService.saveCache(newCompany.encrypt_key)
            if (newCompany) {
              StorageHelpers.setItemLocalStorage(CURRENT_COMPANY_STORAGE, newCompany)
            }
            StorageHelpers.setItemLocalStorage(PERMISSIONS_STORAGE_KEY, userAuth.permissions)
            setCurrentUser(userAuth)
            setCurrentCompany(newCompany)
            StorageHelpers.setItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY, newCompany.encrypt_key)
            if (saveCache) {
              navigate('/dashboard')
              setMeterChange(true)
            }
          }
        } catch (error) {
          navigate('/logout')
        } finally {
          setLoadingSwitch(false)
        }
      }
      callRefreshToken()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (!searchParams.get('token') || !searchParams.get('slug')) {
      navigate('/logout')
    }
    handleRefreshToken()
  }, [handleRefreshToken, searchParams, navigate])

  return (
    <CSSTransition appear in timeout={100} classNames='fade' unmountOnExit>
      <LoadingPage />
    </CSSTransition>
  )
}
