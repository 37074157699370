import React, {FC} from 'react'
import clsx from 'clsx'

type Props = {
  className?: string
  labelClassName?: string | null
  label?: string | null
  rows?: number
  required?: boolean
  formikErrors: any
  formikTouched: any
  fieldProps: any
  append?: string
  bgSolid?: boolean
  disabled?: boolean
}

const InputTextArea: FC<Props> = ({
  className,
  labelClassName = null,
  label = null,
  rows = 5,
  formikErrors,
  formikTouched,
  fieldProps,
  append,
  required = false,
  bgSolid = false,
  disabled = false,
}) => {
  return (
    <>
      {label || labelClassName ? (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      ) : (
        ''
      )}
      <div className={className}>
        <div className='input-group'>
          <textarea
            rows={rows}
            autoComplete='off'
            className={clsx(
              `form-control`,
              {'form-control-solid': bgSolid},
              {'is-invalid': formikTouched && formikErrors},
              {'is-valid': formikTouched && !formikErrors && fieldProps.value}
            )}
            disabled={disabled}
            {...fieldProps}
          />
          {append ? (
            <div className='input-group-append'>
              <span className='input-group-text'>{append}</span>
            </div>
          ) : (
            ''
          )}
        </div>
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {InputTextArea}
