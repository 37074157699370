import clsx from 'clsx'
import React from 'react'
import {KTSVG} from '../../../helpers'

type Props = {
  title?: string
  loading?: any
  className?: string
  icon?: any
  list: any
  menuLeftOrRight?: 'start | end'
}

const DropdownButton: React.FC<Props> = ({
  title,
  className = '',
  menuLeftOrRight = 'end',
  icon,
  list,
  loading,
}) => {
  const renderIcon = () => {
    if (loading) {
      return <div className='ms-2 spinner-border spinner-border-sm' role='status' />
    } else if (icon) {
      return icon
    } else {
      return (
        <KTSVG
          path='/media/gori/claims/three-dots.svg'
          small={false}
          className='rounded-circle bg-hover-light p-1'
        />
      )
    }
  }

  const handleList = list.filter((data) => {
    return !data.hidden
  })

  return (
    <>
      <div
        className={clsx('d-flex align-items-center', className, {
          'pe-none': handleList.length === 0,
        })}
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        {title}
        {renderIcon()}
      </div>
      <div
        className={clsx('dropdown-menu fw-bold fs-7 py-2', {
          [`dropdown-menu-lg-${menuLeftOrRight}`]: menuLeftOrRight,
        })}
      >
        {handleList.length > 0 &&
          handleList.map((child: any, index: number) => {
            return (
              <div
                className={`dropdown-item my-1 text-gray-600 text-hover-dark cursor-pointer fw-bold  ${child?.className}`}
                key={index}
                onClick={child?.action}
              >
                <span className='menu-link'>{child.label}</span>
              </div>
            )
          })}
      </div>
    </>
  )
}

export {DropdownButton}
