import CountUp from 'react-countup'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'
import {Link} from 'react-router-dom'
import {useMeter} from '../../../helpers/components/MeterProvider'
import {SwapperComponent} from '../../../assets/ts/components'
import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../_gori/helpers'
import UseRoutesPermission from '../../../hooks/UseRoutesPermission'

const Topbar = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {meter} = useMeter()
  const navigate = useNavigate()
  const {routes} = UseRoutesPermission()
  return (
    <div className='d-flex align-items-center flex-shrink-0'>
      {currentUser && routes.PAYMENTS.hasPermission && (
        <>
          <div>
            <span className='text-muted fw-bolder me-2'>
              {intl.formatMessage({id: 'BALANCE'})}:
            </span>

            <button
              type='button'
              className='btn btn-outline rounded-pill btn-sm fs-3 fw-boldest py-1 gori-teal-btn'
              onClick={() => navigate('/deposits')}
            >
              $
              <CountUp
                start={0}
                end={meter?.balance}
                separator=','
                decimal={'.'}
                decimals={2}
                className='me-2'
              />
              <KTSVG path='/media/gori/topbar/wallet.svg' className='me-2 svg-icon-2' />
            </button>
          </div>
        </>
      )}

      {!currentUser && (
        <Link
          to='/auth/login'
          onClick={() => SwapperComponent.removeInstances()}
          className='d-flex flex-stack'
        >
          <button type='button' className='btn btn-secondary btn-sm d-xxl-none text-gray-700'>
            {`${intl.formatMessage({id: 'LOGIN'})} / ${intl.formatMessage({id: 'REGISTER'})}`}
          </button>
        </Link>
      )}
    </div>
  )
}

export {Topbar}
