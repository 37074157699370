import {CSSTransition} from 'react-transition-group'
import {toAbsoluteUrl} from '../../../_gori/helpers'
import {Footer} from './components/Footer'
import {Header} from './components/Header'

export function PrivacyPolicyPage() {
  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <>
        <Header />

        {/* tittle */}
        <div className='mt-20'>
          <div className='container mt-20'>
            <div className='justify-content-end d-flex'>
              <img
                src={toAbsoluteUrl('/media/gori/policies/check.png')}
                className='w-90px me-20'
                alt=''
              />
            </div>
            <div className='justify-content-center d-flex'>
              <div className='text-center display-2 fw-bolder pb-12' style={{color: '#16BAA8'}}>
                Privacy Policy
              </div>
            </div>
            <div className='justify-content-start d-flex'>
              <img
                src={toAbsoluteUrl('/media/gori/policies/truck.png')}
                className='w-200px mx-5'
                alt=''
              />
            </div>
          </div>
        </div>

        {/* policy */}
        <div className='bg-white py-20'>
          <div className='container mb-10'>
            <div className='mt-5 pb-6'>
              <h3 className='pb-3'>What personal information we collect</h3>
              <p>
                ShippingItNow collects personally identifiable information when you register on our
                site. The information collected may include any of the following: email, name, IP
                address, browser information, address, billing address. Any comments or questions
                you submit are recorded as well. When entering ShippingItNow’s site, information may
                be collected about previous sites the person has viewed.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>How we use the information collected</h3>
              <p>
                The goal of collecting customer and viewer information is to create a friendlier,
                smooth, efficient, safe and customized experience while working with ShippingItNow.
                Personal information submitted at the time of registration may be used by
                ShippingItNow for the marketing and promotion of ShippingItNow products that we feel
                may be of interest to you.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>We do not disclose your information to thirdparties</h3>
              <p>
                We take your privacy seriously. ShippingItNow will not share your information with
                thirdparties.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>Other information</h3>
              <p>
                By submitting personal information to us or using our site, you agree that we may
                collect and use such personal information in accordance with our privacy policy or
                as permitted by law. If you do not agree with these terms, do not provide any
                personal information to us. If you refuse or withdraw consent, or do not provide us
                with any necessary personal information, we may not be able to provide you with
                service.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>Removal from our Database</h3>
              <p>
                To request removal from the database, simply contact us and we will be happy to
                remove your information immediately. Every email sent to you from ShippingItNow will
                also include a link to remove your email address from the mail server. If you
                request that we modify or remove all of your personal information, we will promptly
                do so, to the extent possible. Once your personal information has been removed you
                will not be able to use our services unless you re-register.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>Buyer Information</h3>
              <p>
                When you use ShippingItNow, your buyer information will download into your
                ShippingItNow Account and some of these information may be considered Personal
                Information. For example, Name, Address, Email and Phone Number are considered
                personal information. ShippingItNow do not disclose such personal information to any
                3rd party, unless authorized by you ahead of time or as part of your normal
                operations in using ShippingItNow software. For example, "Personal Information" may
                be passed to any shipping carrier in creating a shipping label, or submitted to 3PL
                when you submit your order to a drop-shipper, or exported to 3rd party accounting
                software when you link such software to ShippingItNow. Outside of such mechanisms,
                ShippingItNow do not disclose Buyer Personal Information to any 3rd party.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>Buyer Information Retention</h3>
              <p>
                Buyer Personal Identifying Information may be removed at your request, or may be
                removed when the license ShippingItNow operates under access certain platforms
                forces ShippingItNow to remove them under specific conditions (age of order, etc).
                When you close your ShippingItNow Account, buyer information is removed soon after
                the account closure and is not recoverable. You can also request ShippingItNow to
                remove buyer information while your account is still open, subject to reasonable
                fees per request.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>Notification of Information Breach</h3>
              <p>
                ShippingItNow will notify account owner if we discover breach of personal
                information, either your own information, or your buyer's personal identifiable
                information. ShippingItNow may also notify e-commerce platform of such information
                breach. However, it is up to you to notify buyers on any information breach.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>Subpoenas and Law Enforcement Requests</h3>
              <p>
                The above policy of not disclosing personal information do not apply if
                ShippingItNow is subject to any subpoena and government power to disclose
                information to law enforcement and or other government agency. Under such situations
                ShippingItNow may be forced to pass personal information to government agencies. In
                such cases while we attempt to provide prior notice to our users, we may not be able
                to do so under all cases.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>Questions, comments, concerns</h3>
              <p>
                All questions, comments and concerns can be submitted via the contact us form. You
                will receive an answer as soon as possible.
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </>
    </CSSTransition>
  )
}
