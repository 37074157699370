import clsx from 'clsx'
import React from 'react'

type Props = {
  className?: string
  children?: any
}

const TableBody: React.FC<Props> = ({className, children}) => {
  return <div className={clsx(`card-body pt-0 d-flex flex-column`, className)}>{children}</div>
}

export {TableBody}
