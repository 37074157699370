export const KEY_PERMISSIONS = {
  ADMIN: 'admin',
  COMPANY: 'company',
  SHIPMENTS: 'shipments',
  REPORTS_SHIPMENT: 'reports:shipment',
  REPORTS_TRANSACTIONS: 'reports:transactions',
  CLAIMS: 'claims',
  ORDERS: 'orders',
  DASHBOARD: 'dashboard',
  USERS: 'users',
  SHIPMENTS_UPDATE: 'shipments:update',
  CLAIMS_VIEW: 'claims:view',
  CLAIMS_CREATE: 'claims:create',
  CLAIMS_UPDATE: 'claims:update',
  CLAIMS_APPEAL: 'claims:appeal',
  CLAIMS_DELETE: 'claims:delete',
  ORDERS_VIEW: 'orders:view',
  ORDERS_CREATE: 'orders:create',
  ORDERS_UPDATE: 'orders:update',
  ORDERS_CANCEL: 'orders:cancel',
  ORDERS_HOLD: 'orders:hold',
  ORDERS_REMOVE_HOLD: 'orders:remove_hold',
  ORDERS_SYNC: 'orders:sync',
  ORDERS_PATCH: 'orders:patch',
  ORDERS_CREATE_LABEL: 'orders:create_label',
  ORDERS_ASSIGN: 'orders:assign',
  ORDERS_UN_ASSIGN: 'orders:unassign',
  ORDERS_RESTORE: 'orders:restore',
  ORDERS_COMBINE: 'orders:combine',
  ORDERS_SPLIT: 'orders:split',
  ORDERS_SHIPPING_PRESET: 'orders:shipping-preset',
  SHIPPING_ADDRESSES_CREATE: 'shipping_addresses:create',
  SHIPPING_ADDRESSES_UPDATE: 'shipping_addresses:update',
  SHIPPING_ADDRESSES_DELETE: 'shipping_addresses:delete',
  USERS_VIEW: 'users:view',
  USERS_CREATE: 'users:create',
  USERS_UPDATE: 'users:update',
  USERS_VIEW_LOG: 'users:view_log',
  USERS_REMOVE: 'users:remove',
  USERS_INVITE: 'users:invite',
  PAYMENTS: 'payments',
  BATCHES: 'batches',
  BATCHES_VIEW: 'batches:view',
  BATCHES_CREATE: 'batches:create',
  BATCHES_UPDATE: 'batches:update',
  BATCHES_DELETE: 'batches:delete',
}

export const ROUTING_PERMISSIONS = {
  DASHBOARD: {
    hasPermission: false,
    route: '/dashboard',
    roles: [KEY_PERMISSIONS.ADMIN, KEY_PERMISSIONS.COMPANY, KEY_PERMISSIONS.DASHBOARD],
  },
  ORDERS: {
    hasPermission: false,
    route: '/orders',
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.ORDERS,
      KEY_PERMISSIONS.ORDERS_VIEW,
      KEY_PERMISSIONS.ORDERS_CREATE,
      KEY_PERMISSIONS.ORDERS_UPDATE,
      KEY_PERMISSIONS.ORDERS_CANCEL,
      KEY_PERMISSIONS.ORDERS_HOLD,
      KEY_PERMISSIONS.ORDERS_REMOVE_HOLD,
      KEY_PERMISSIONS.ORDERS_CREATE_LABEL,
      KEY_PERMISSIONS.ORDERS_SYNC,
      KEY_PERMISSIONS.ORDERS_PATCH,
      KEY_PERMISSIONS.BATCHES_CREATE,
      KEY_PERMISSIONS.ORDERS_ASSIGN,
    ],
    subRoutes: ['/orders', '/batches'],
  },
  SHIPMENTS: {
    hasPermission: false,
    route: '/shipments',
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.SHIPMENTS,
      KEY_PERMISSIONS.REPORTS_SHIPMENT,
      KEY_PERMISSIONS.SHIPMENTS_UPDATE,
    ],
    subRoutes: ['/shipments', '/claims'],
  },
  REPORTS_SHIPMENT: {
    hasPermission: false,
    route: '/reports',
    roles: [KEY_PERMISSIONS.ADMIN, KEY_PERMISSIONS.COMPANY, KEY_PERMISSIONS.REPORTS_SHIPMENT],
  },
  REPORTS_TRANSACTIONS: {
    hasPermission: false,
    route: '/reports',
    roles: [KEY_PERMISSIONS.ADMIN, KEY_PERMISSIONS.COMPANY, KEY_PERMISSIONS.REPORTS_TRANSACTIONS],
  },
  CLAIMS: {
    hasPermission: false,
    route: '/claims',
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.CLAIMS,
      KEY_PERMISSIONS.CLAIMS_VIEW,
      KEY_PERMISSIONS.CLAIMS_CREATE,
      KEY_PERMISSIONS.CLAIMS_UPDATE,
      KEY_PERMISSIONS.CLAIMS_APPEAL,
      KEY_PERMISSIONS.CLAIMS_DELETE,
    ],
  },
  BATCHES: {
    hasPermission: false,
    route: '/batches',
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.BATCHES,
      KEY_PERMISSIONS.BATCHES_VIEW,
      KEY_PERMISSIONS.BATCHES_CREATE,
      KEY_PERMISSIONS.BATCHES_UPDATE,
      KEY_PERMISSIONS.BATCHES_DELETE,
      KEY_PERMISSIONS.ORDERS_CANCEL,
    ],
  },
  USERS: {
    hasPermission: false,
    route: '/settings/users',
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.USERS,
      KEY_PERMISSIONS.USERS_CREATE,
      KEY_PERMISSIONS.USERS_UPDATE,
      KEY_PERMISSIONS.USERS_VIEW,
      KEY_PERMISSIONS.USERS_VIEW_LOG,
      KEY_PERMISSIONS.USERS_REMOVE,
      KEY_PERMISSIONS.USERS_INVITE,
    ],
  },
  USERS_VIEW_LOG: {
    hasPermission: false,
    route: '/settings/users',
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.USERS,
      KEY_PERMISSIONS.USERS_VIEW_LOG,
    ],
  },
  NIGHT_WATCH: {
    hasPermission: false,
    route: '/nightwatch',
    roles: [KEY_PERMISSIONS.ADMIN, KEY_PERMISSIONS.COMPANY],
    subRoutes: ['/nightwatch'],
  },
  DOCS: {
    hasPermission: true,
    route: '/docs',
  },
  SUPPORTS: {
    hasPermission: true,
    route: '/support',
  },
  SETTINGS: {
    hasPermission: false,
    route: '/settings',
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.SHIPPING_ADDRESSES_CREATE,
      KEY_PERMISSIONS.USERS_CREATE,
      KEY_PERMISSIONS.USERS_UPDATE,
      KEY_PERMISSIONS.USERS_VIEW,
      KEY_PERMISSIONS.SHIPPING_ADDRESSES_UPDATE,
    ],
  },
  USERS_CREATE: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.USERS,
      KEY_PERMISSIONS.USERS_CREATE,
    ],
  },
  USERS_UPDATE: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.USERS,
      KEY_PERMISSIONS.USERS_UPDATE,
    ],
  },
  USERS_REMOVE: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.USERS,
      KEY_PERMISSIONS.USERS_REMOVE,
    ],
  },
  USERS_INVITE: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.USERS,
      KEY_PERMISSIONS.USERS_INVITE,
    ],
  },
  USERS_VIEW: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.USERS_VIEW],
  },
  SHIPPING_ADDRESSES: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.SHIPPING_ADDRESSES_CREATE,
      KEY_PERMISSIONS.SHIPPING_ADDRESSES_UPDATE,
      KEY_PERMISSIONS.SHIPPING_ADDRESSES_DELETE,
    ],
  },
  SHIPPING_ADDRESSES_CREATE: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.SHIPPING_ADDRESSES_CREATE,
    ],
  },
  SHIPPING_ADDRESSES_UPDATE: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.SHIPPING_ADDRESSES_UPDATE,
    ],
  },
  SHIPPING_ADDRESSES_DELETE: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.SHIPPING_ADDRESSES_DELETE,
    ],
  },
  SHIPPING_ADDRESSES_VIEW: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.ADMIN, KEY_PERMISSIONS.COMPANY],
  },
  CLAIMS_CREATE: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.CLAIMS,
      KEY_PERMISSIONS.CLAIMS_CREATE,
    ],
  },
  CLAIMS_UPDATE: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.CLAIMS,
      KEY_PERMISSIONS.CLAIMS_UPDATE,
    ],
  },
  CLAIMS_APPEAL: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.CLAIMS,
      KEY_PERMISSIONS.CLAIMS_APPEAL,
    ],
  },
  CLAIMS_DELETE: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.CLAIMS,
      KEY_PERMISSIONS.CLAIMS_DELETE,
    ],
  },
  CLAIMS_VIEW: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.CLAIMS_VIEW],
  },
  COMPANY_ADMIN: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.COMPANY, KEY_PERMISSIONS.ADMIN],
  },
  PAYMENTS: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.COMPANY, KEY_PERMISSIONS.ADMIN, KEY_PERMISSIONS.PAYMENTS],
  },
  BATCHES_CREATE: {
    hasPermission: false,
    route: '/batches',
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.BATCHES,
      KEY_PERMISSIONS.BATCHES_CREATE,
    ],
  },
  BATCHES_UPDATE: {
    hasPermission: false,
    route: '/batches',
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.BATCHES,
      KEY_PERMISSIONS.BATCHES_UPDATE,
    ],
  },
  BATCHES_DELETE: {
    hasPermission: false,
    route: '/batches',
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.BATCHES,
      KEY_PERMISSIONS.BATCHES_DELETE,
    ],
  },
  BATCHES_VIEW: {
    hasPermission: false,
    route: '/batches',
    roles: [KEY_PERMISSIONS.ADMIN, KEY_PERMISSIONS.COMPANY, KEY_PERMISSIONS.BATCHES_VIEW],
  },
  SHIPMENT_VIEW: {
    hasPermission: false,
    route: '/shipments',
    roles: [KEY_PERMISSIONS.ADMIN, KEY_PERMISSIONS.COMPANY, KEY_PERMISSIONS.SHIPMENTS],
    subRoutes: ['/shipments', '/claims'],
  },
  SHIPMENT_UPDATE: {
    hasPermission: false,
    route: '/shipments',
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.SHIPMENTS,
      KEY_PERMISSIONS.SHIPMENTS_UPDATE,
    ],
    subRoutes: ['/shipments', '/claims'],
  },
  ORDERS_VIEW: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.ORDERS_VIEW],
  },
  ORDERS_CANCEL: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.BATCHES,
      KEY_PERMISSIONS.ORDERS_CANCEL,
    ],
  },
  ORDERS_RESTORE: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.BATCHES,
      KEY_PERMISSIONS.ORDERS_RESTORE,
    ],
  },
  ORDERS_CREATE: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.ORDERS,
      KEY_PERMISSIONS.ORDERS_CREATE,
    ],
  },
  ORDERS_UPDATE: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.ORDERS,
      KEY_PERMISSIONS.ORDERS_UPDATE,
    ],
  },
  ORDERS_CREATE_LABEL: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.ORDERS,
      KEY_PERMISSIONS.ORDERS_CREATE_LABEL,
    ],
  },
  ORDERS_PATCH: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.ORDERS,
      KEY_PERMISSIONS.ORDERS_UPDATE,
      KEY_PERMISSIONS.ORDERS_PATCH,
    ],
  },
  ORDERS_HOLD: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.ORDERS,
      KEY_PERMISSIONS.ORDERS_HOLD,
    ],
  },
  ORDERS_REMOVE_HOLD: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.ORDERS,
      KEY_PERMISSIONS.ORDERS_REMOVE_HOLD,
    ],
  },
  ORDERS_ASSIGN: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.ORDERS,
      KEY_PERMISSIONS.ORDERS_ASSIGN,
    ],
  },
  ORDERS_UN_ASSIGN: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.ORDERS,
      KEY_PERMISSIONS.ORDERS_UN_ASSIGN,
    ],
  },
  ORDERS_COMBINE: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.ORDERS,
      KEY_PERMISSIONS.ORDERS_COMBINE,
    ],
  },
  ORDERS_SPLIT: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.ORDERS,
      KEY_PERMISSIONS.ORDERS_SPLIT,
    ],
  },
  ORDERS_SHIPPING_PRESET: {
    hasPermission: false,
    roles: [
      KEY_PERMISSIONS.ADMIN,
      KEY_PERMISSIONS.COMPANY,
      KEY_PERMISSIONS.ORDERS,
      KEY_PERMISSIONS.ORDERS_SHIPPING_PRESET,
    ],
  },
}
