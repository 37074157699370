import {useEffect} from 'react'
import {useAuth} from './core/Auth'

const baseDomain = process.env.REACT_APP_DOMAIN

export function Logout() {
  const {logout} = useAuth()

  useEffect(() => {
    logout()

    if (document.location.host !== baseDomain) {
      const protocal = document.location.protocol
      document.location.replace(protocal + '//' + baseDomain + '/auth/login')
    }
  }, [logout])

  return null
}
