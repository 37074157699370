import {FormikProps, getIn} from 'formik'
import {cloneDeep, isEqual} from 'lodash'
import {FC, useMemo} from 'react'
import Select from 'react-select'
import {DEFAULT_COLOR, INVALID_COLOR, VALID_COLOR} from '../../../constants'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  onChange?: any
  className?: string
  labelClassName?: string
  classNameSelect?: string
  label?: string
  options: Array<any>
  required?: boolean
  formik: FormikProps<any>
  name: string
  placeholder?: string
  emptyDefault?: boolean
  disabled?: boolean
  onFocus?: any
}

const SelectMultiLevelFormik: FC<Props> = ({
  className = '',
  labelClassName = '',
  classNameSelect = '',
  label = null,
  options,
  required = false,
  formik,
  name,
  placeholder,
  emptyDefault = true,
  disabled = false,
  onFocus,
}) => {
  let fieldProps = formik.getFieldProps(name)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)

  let isInvalid = formikTouched && formikErrors
  let isValid = formikTouched && !formikErrors && fieldProps.value
  const customStyles = {
    menuPortal: (base) => ({...base, zIndex: 9999}),
    option: (styles) => ({...styles, minHeight: 30}),
    control: (base, state) => ({
      ...base,
      backgroundImage: isValid
        ? `url(${toAbsoluteUrl('/media/gori/valid.svg')})`
        : isInvalid
        ? `url(${toAbsoluteUrl('/media/gori/invalid.svg')})`
        : '',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 3.5rem center',
      backgroundSize: 'calc(0.75em + 0.75rem) calc(0.75em + 0.75rem)',
      borderColor: isValid ? VALID_COLOR : isInvalid ? INVALID_COLOR : DEFAULT_COLOR,
      '&:hover': {
        borderColor: isValid ? VALID_COLOR : isInvalid ? INVALID_COLOR : DEFAULT_COLOR,
      },
      boxShadow: isValid ? VALID_COLOR : isInvalid ? INVALID_COLOR : DEFAULT_COLOR,
    }),
  }

  const value = useMemo(() => {
    const optionsValue: any = []
    cloneDeep(options).forEach((item: any) => optionsValue.push(...item.options))

    const option = cloneDeep(optionsValue).filter((item) => {
      if (isEqual(item.value, fieldProps.value)) {
        return item
      } else {
        return undefined
      }
    })
    return option[0]
  }, [fieldProps.value, options])

  return (
    <>
      {label && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <div className={className}>
        <Select
          isDisabled={disabled}
          className={classNameSelect}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: 8,
          })}
          options={emptyDefault ? [{label: '', value: ''}, ...options] : options}
          placeholder={placeholder}
          onFocus={onFocus}
          {...fieldProps}
          value={value ? value : ''}
          onChange={(option: any) => {
            formik.setFieldValue(fieldProps.name, option.value)
          }}
        />
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {SelectMultiLevelFormik}
