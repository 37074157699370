import moment from 'moment'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

export function Footer() {
  const intl = useIntl()
  return (
    <div className='bg-dark'>
      <div className='container'>
        <footer className='d-flex flex-wrap justify-content-between align-items-center py-3 my-4 my-20'>
          <Link
            to='/landing'
            className='col-md-3 d-flex align-items-center justify-content-start text-white fs-1 fw-bolder text-decoration-none'
          >
            <div className='me-10'>Shippingitnow</div>
            <div className='vr'></div>
          </Link>

          <ul className='nav col-md-5'>
            <li className='nav-item'>
              <Link to='/service-terms' className='nav-link me-5 text-white fs-4'>
                {intl.formatMessage({id: 'TERMS_OF_SERVICE'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/privacy-policy' className='nav-link  text-white fs-4'>
                {intl.formatMessage({id: 'PRIVACY_POLICY'})}
              </Link>
            </li>
          </ul>

          <p className='col-md-4 mb-0 text-white d-flex justify-content-end'>
            &copy; Shippingitnow Copyright {moment().year()} All Right Reserved
          </p>
        </footer>
      </div>
    </div>
  )
}
