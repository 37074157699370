import axios, {AxiosRequestConfig} from 'axios'
import AuthRequestBase from '../../../api/AuthRequestBase'
import RequestBase from '../../../api/RequestBase'

const GRANT_TYPE = process.env.REACT_APP_GRANT_TYPE
const GRANT_TYPE_REFRESH = process.env.REACT_APP_GRANT_TYPE_REFRESH
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET
const API_VERSION = process.env.REACT_APP_API_VERSION
const BASE_URL = `/${API_VERSION}/auth`
const SIGNATURE_ACCESS_KEY: any = process.env.REACT_APP_SIGNATURE_ACCESS_KEY

function login(payload: any, config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.post(
    `/${API_VERSION}/login`,
    {
      grant_type: GRANT_TYPE,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      ...payload,
    },
    config
  )
}

function refreshToken(payload: any, config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.post(
    `/${API_VERSION}/login`,
    {
      grant_type: GRANT_TYPE_REFRESH,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      ...payload,
    },
    config
  )
}

function saveCache(payload: any, config?: AxiosRequestConfig | undefined) {
  axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = payload
  payload = {}
  return RequestBase.post('/cache', payload, config)
}

function register(payload: any, config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.post(`${BASE_URL}/register`, payload, config)
}

function active(payload: any, config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.post(`${BASE_URL}/active`, payload, config)
}

function resetPassword(payload: any, config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.post(`${BASE_URL}/reset-password`, payload, config)
}

function newPassword(payload: any, config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.post(`${BASE_URL}/new-password?token=${payload.token}`, payload, config)
}

function activeUser(payload: any, config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.post(`/${API_VERSION}/users/active-account`, payload, config)
}

function checkSubdomainValid(config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.get(`/${API_VERSION}/check-subdomain`, config)
}

function getVersion(config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.get(`/${API_VERSION}/get-version`, config)
}

function resendVerificationEmail(payload: any, config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.post(`/${BASE_URL}/resend-verification-email`, payload, config)
}

function getListSlugs(payload: any, config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.post(`/${BASE_URL}/get-slugs`, payload, config)
}

function createSlug(payload: any, config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.post(`/${BASE_URL}/create-slug`, payload, config)
}

function checkVerified(config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.get(`/${API_VERSION}/check-verified`, config)
}

const AuthService = {
  login,
  saveCache,
  refreshToken,
  register,
  active,
  resetPassword,
  newPassword,
  activeUser,
  checkSubdomainValid,
  getVersion,
  resendVerificationEmail,
  getListSlugs,
  createSlug,
  checkVerified,
}

export default AuthService
