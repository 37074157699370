import React from 'react'

type Props = {
  className?: string
  children?: any
}

const TableHeader: React.FC<Props> = ({className = '', children}) => {
  return <div className={`card-header border-0 ${className}`}>{children}</div>
}

export {TableHeader}
