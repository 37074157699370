import React, {FC} from 'react'
import clsx from 'clsx'
import {FormikProps, getIn} from 'formik'

type Props = {
  onChange?: any
  className?: string
  labelClassName?: string | null
  inputClassName?: string | null
  label?: string | null
  size?: 'sm' | 'lg' | null
  type?: 'password' | 'text' | 'number'
  placeholder?: string
  required?: boolean
  formik: FormikProps<any>
  name?: any
  append?: string
  bgSolid?: boolean
  min?: number
  max?: number
  isInteger?: boolean
  checkFormik?: boolean
  disabled?: boolean
  onFocus?: any
  hidden?: boolean
  children?: any
}

const InputTextFormik: FC<Props> = ({
  onChange = undefined,
  className,
  labelClassName = '',
  inputClassName = '',
  label = null,
  size = '',
  type = 'text',
  placeholder = '',
  required = false,
  formik,
  name,
  append,
  bgSolid = false,
  min,
  max,
  isInteger,
  checkFormik = true,
  disabled = false,
  onFocus,
  hidden = false,
  children,
}) => {
  let fieldProps = formik.getFieldProps(name)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)

  return (
    <>
      {label && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <div className={className}>
        <div className='input-group'>
          <input
            disabled={disabled}
            type={type}
            autoComplete='off'
            className={clsx(
              `form-control ${inputClassName}`,
              {[`form-control-${size}`]: size},
              {'form-control-solid': bgSolid},
              {'is-invalid': formikTouched && formikErrors && checkFormik},
              {'is-valid': formikTouched && !formikErrors && fieldProps.value && checkFormik}
            )}
            {...fieldProps}
            placeholder={placeholder}
            min={min}
            max={max}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key) && isInteger) {
                event.preventDefault()
              }
            }}
            onFocus={onFocus}
            hidden={hidden}
            onChange={(value: any) => {
              if (!/[0-9]/.test(value.target.value) && isInteger) {
                value.preventDefault()
              }
              let event = {target: {name: fieldProps.name, value: value.target.value}}
              formik.handleChange(event)
              if (onChange) {
                onChange(value)
              }
            }}
          />
          {append && (
            <div className='input-group-append'>
              <span className='input-group-text'>{append}</span>
            </div>
          )}
        </div>
        {children}
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {InputTextFormik}
