import React, {FC} from 'react'
import clsx from 'clsx'

type Props = {
  className?: string
  labelClassName?: string | null
  label?: string | null
  rows?: number
  required?: boolean
  append?: string
  bgSolid?: boolean
  onChange?: any
  value?: any
  disabled?: boolean
}

const InputTextAreaNoFormik: FC<Props> = ({
  className,
  labelClassName = null,
  label = null,
  rows = 5,
  append,
  required = false,
  bgSolid = false,
  onChange,
  value,
  disabled,
}) => {
  return (
    <>
      {label || labelClassName ? (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      ) : (
        ''
      )}
      <div className={className}>
        <div className='input-group'>
          <textarea
            rows={rows}
            autoComplete='off'
            className={clsx(`form-control`, {
              'form-control-solid': bgSolid,
              'cursor-no-drop': disabled,
            })}
            onChange={onChange}
            value={value}
            disabled={disabled}
          />
          {append ? (
            <div className='input-group-append'>
              <span className='input-group-text'>{append}</span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}

export {InputTextAreaNoFormik}
