import React, {FC, useMemo, useRef} from 'react'
import Select from 'react-select'
import {DEFAULT_COLOR} from '../../../constants'
import clsx from 'clsx'

type Props = {
  onChange?: any
  className?: string
  labelClassName?: string
  classNameSelect?: string
  label?: string
  options: Array<any>
  value?: any
  required?: boolean
  name?: string
  placeholder?: string
  emptyDefault?: boolean
  disabled?: boolean
  styleInput?: any
  isSearchable?: boolean
}

const SelectNoFormik: FC<Props> = ({
  onChange = undefined,
  className = '',
  labelClassName = '',
  classNameSelect = '',
  label = null,
  options,
  value,
  required = false,
  name,
  placeholder = '',
  emptyDefault = false,
  disabled,
  styleInput,
  isSearchable = true,
}) => {
  const customStyles = {
    menuPortal: (base) => ({...base, zIndex: 9999}),
    option: (styles, {isDisabled}) => ({
      ...styles,
      minHeight: 30,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    }),
    control: (base) => ({
      ...base,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 3.5rem center',
      backgroundSize: 'calc(0.75em + 0.75rem) calc(0.75em + 0.75rem)',
      background: '#f8f9fa',
      borderColor: DEFAULT_COLOR,
      '&:hover': {
        borderColor: DEFAULT_COLOR,
      },
      boxShadow: DEFAULT_COLOR,
      ...styleInput,
    }),
  }
  const selectOptions = useRef([{value: '', label: '', disabled: false}])
  useMemo(() => {
    if (!emptyDefault) {
      selectOptions.current = options
    } else if (options) {
      selectOptions.current = [...selectOptions.current, ...options]
    }
  }, [emptyDefault, options])

  return (
    <>
      {label && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <div className={clsx(className, {'cursor-no-drop': disabled})}>
        <Select
          name={name}
          className={classNameSelect}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: 8,
            colors: {
              ...theme.colors,
              primary: '#009ef7',
            },
          })}
          options={selectOptions.current}
          value={value}
          isDisabled={disabled}
          placeholder={placeholder}
          isSearchable={isSearchable}
          {...(onChange ? {onChange: onChange} : {})}
          isOptionDisabled={(option) => option.disabled === true}
        />
      </div>
    </>
  )
}

export {SelectNoFormik}
