import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../../api/RequestBase'

const getAll = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get('/carriers', config)
}

const CarrierService = {
  getAll,
}

export default CarrierService
