import React, {FC, useMemo, useRef} from 'react'
import Select from 'react-select'
import {FormikProps, getIn} from 'formik'
import {toAbsoluteUrl} from '../../../helpers'
import {DEFAULT_COLOR, INVALID_COLOR, VALID_COLOR} from '../../../constants'
import {isEmpty} from 'lodash'

type Props = {
  onChange?: any
  className?: string
  labelClassName?: string
  classNameSelect?: string
  label?: string
  options: Array<any>
  required?: boolean
  formik: FormikProps<any>
  name: string
  placeholder?: string
  emptyDefault?: boolean
  checkFormik?: boolean
  disabled?: boolean
  onFocus?: any
  styleInput?: any
  isSearchable?: boolean
}

const SelectFormik: FC<Props> = ({
  onChange = undefined,
  className = '',
  labelClassName = '',
  classNameSelect = '',
  label = null,
  options,
  required = false,
  formik,
  name,
  placeholder,
  emptyDefault = true,
  checkFormik = true,
  disabled = false,
  onFocus,
  styleInput,
  isSearchable = true,
}) => {
  let fieldProps = formik.getFieldProps(name)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)

  let isInvalid = formikTouched && formikErrors && checkFormik
  let isValid = formikTouched && !formikErrors && fieldProps.value && checkFormik
  const customStyles = {
    menuPortal: (base) => ({...base, zIndex: 9999}),
    option: (styles, {data}) => ({
      ...styles,
      minHeight: 30,
      color: !isEmpty(data?.action) ? '#009ef7' : '',
      fontWeight: !isEmpty(data?.action) ? 'bold' : '',
      cursor: !isEmpty(data?.action) ? 'pointer' : '',
    }),
    control: (base) => ({
      ...base,
      backgroundImage: isValid
        ? `url(${toAbsoluteUrl('/media/gori/valid.svg')})`
        : isInvalid
        ? `url(${toAbsoluteUrl('/media/gori/invalid.svg')})`
        : '',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 3.5rem center',
      backgroundSize: 'calc(0.75em + 0.75rem) calc(0.75em + 0.75rem)',
      background: '#f8f9fa',
      borderColor: DEFAULT_COLOR,
      '&:hover': {
        borderColor: isValid ? VALID_COLOR : isInvalid ? INVALID_COLOR : DEFAULT_COLOR,
      },
      boxShadow: isValid ? VALID_COLOR : isInvalid ? INVALID_COLOR : DEFAULT_COLOR,
      ...styleInput,
    }),
  }

  const selectOptions = useRef([{value: '', label: ''}])
  useMemo(() => {
    if (!emptyDefault) {
      selectOptions.current = options
    } else if (options) {
      selectOptions.current = [...selectOptions.current, ...options]
    }
  }, [emptyDefault, options])

  return (
    <>
      {label && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <div className={className}>
        <Select
          isDisabled={disabled}
          name={name}
          className={classNameSelect}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: 8,
          })}
          options={selectOptions.current}
          value={
            !isEmpty(selectOptions.current)
              ? selectOptions.current.find((option) => option.value === fieldProps.value)
              : ''
          }
          onBlur={() => {
            formik.handleBlur({target: {name: fieldProps.name}})
          }}
          placeholder={placeholder}
          onChange={(option: any) => {
            let event = {target: {name: fieldProps.name, value: option.value}}
            formik.handleChange(event)
            if (onChange) {
              onChange(option)
            }
          }}
          onFocus={onFocus}
          isSearchable={isSearchable}
        />
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {SelectFormik}
