import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'
import AuthRequestBase from '../../../api/AuthRequestBase'

const API_VERSION = process.env.REACT_APP_API_VERSION

const URL_SHIPPING = '/shipping-addresses'
const URL_SHIPPING_PRESETS = '/shipping-presets'
const URL_STORE = '/connections'
const URL_PACKAGES = '/carriers/packages'
const URL_SHIPSTATION = `/shipstation`
const URL_USERS = `/${API_VERSION}/users`

// shipping
const getOptionsAddress = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_SHIPPING}`, config)
}

const getAddress = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_SHIPPING}`, config)
}

const saveAddress = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_SHIPPING}`, payload, config)
}

const editAddress = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL_SHIPPING}`, payload, config)
}

const deleteAddress = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_SHIPPING}/delete`, payload, config)
}

const getPackages = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_PACKAGES}`, config)
}

const getPackageDetail = (id?: string, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_PACKAGES}/${id}`, config)
}

const createPackages = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_PACKAGES}`, payload, config)
}

const editPackage = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL_PACKAGES}`, payload, config)
}

const deletePackage = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_PACKAGES}/delete`, payload, config)
}

const getShippingPresets = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_SHIPPING_PRESETS}`, config)
}

const createShippingPreset = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_SHIPPING_PRESETS}`, payload, config)
}

const updateShippingPreset = (
  id: string,
  payload: any,
  config?: AxiosRequestConfig | undefined
) => {
  return RequestBase.put(`${URL_SHIPPING_PRESETS}/${id}`, payload, config)
}

const deleteShippingPreset = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_SHIPPING_PRESETS}/delete`, payload, config)
}

// store
const webhookUrl = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_STORE}/webhook-url`, config)
}

const connections = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_STORE}`, config)
}

const connect = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_STORE}`, payload, config)
}

const disconnect = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.remove(`${URL_STORE}`, config)
}

const getShopifyIntegrateLink = (shopUrl, config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.get('/shopify/install-link?shop=' + shopUrl, config)
}

const frontToken = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_SHIPSTATION}/front-token`, payload, config)
}

// general
const updateGeneral = (id: any, payload: any, config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.put(`${URL_USERS}/profile/general/${id}`, payload, config)
}

const getAllLog = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get('/logs', config)
}

const SettingsService = {
  //shipping
  getOptionsAddress,
  getAddress,
  saveAddress,
  editAddress,
  deleteAddress,
  getPackages,
  deletePackage,
  getPackageDetail,
  createPackages,
  editPackage,
  getShippingPresets,
  createShippingPreset,
  updateShippingPreset,
  deleteShippingPreset,

  //store
  frontToken,
  connections,
  connect,
  disconnect,
  getShopifyIntegrateLink,
  webhookUrl,

  // general
  updateGeneral,

  // Log
  getAllLog,
}

export default SettingsService
