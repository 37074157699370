import {FC, useCallback, useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useSearchParams} from 'react-router-dom'
import {DEFAULT_PAGE} from '../../../constants'
import {isEmpty} from 'lodash'

type Props = {
  placeholder?: string
}

const InputSearch: FC<Props> = ({placeholder}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchText, setSearchText] = useState(searchParams.get('search_text') || '')

  const handleSearch = useCallback(
    (e) => {
      if (isEmpty(searchText)) {
        searchParams.delete('search_text')
      } else {
        searchParams.set('search_text', searchText)
      }

      if (e.key === 'Enter' || isEmpty(searchText)) {
        searchParams.set('page', DEFAULT_PAGE)
        setSearchParams(searchParams)
      }
    },
    [searchParams, searchText, setSearchParams]
  )

  useEffect(() => {
    setSearchText(searchParams.get('search_text') || '')
  }, [searchParams])

  return (
    <>
      <KTSVG path='/media/gori/magnify.svg' className='svg-icon-1 position-absolute ms-4' />
      <input
        type='text'
        autoComplete='off'
        className='form-control form-control-solid w-250px ps-14'
        placeholder={placeholder}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyUp={(e) => handleSearch(e)}
        value={searchText}
      />
    </>
  )
}

export {InputSearch}
