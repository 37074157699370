import React, {FC} from 'react'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment/moment'
import {FormikProps, getIn} from 'formik'
import {isEmpty} from 'lodash'

type Props = {
  className?: string
  labelClassName?: string | null
  label?: string | null
  required?: boolean
  formik: FormikProps<any>
  name: string
  append?: string
  disabled?: boolean
  min?: any
}

const InputDate: FC<Props> = ({
  className,
  labelClassName = null,
  label = null,
  formik,
  name,
  required = false,
  disabled = false,
  min = null,
}) => {
  let fieldProps = formik.getFieldProps(name)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)
  formik.values[name] = isEmpty(formik.values[name]) ? undefined : moment(formik.values[name])
  return (
    <>
      {label && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <div className={className}>
        <div className='input-group'>
          <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
            <DatePicker
              minDate={min}
              disabled={disabled}
              defaultValue={formik.values[name]}
              onChange={(value) => {
                let event = {
                  target: {
                    name: fieldProps.name,
                    value: moment(value).format(),
                  },
                }
                formik.handleChange(event)
              }}
            />
          </LocalizationProvider>
        </div>
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {InputDate}
