import clsx from 'clsx'
import {getIn} from 'formik'
import React, {FC} from 'react'

type Props = {
  className?: string
  labelClassName?: string | null
  label?: string | null
  size?: 'sm' | 'lg' | null
  openVisibility?: boolean
  openHighlight?: boolean
  strengthMessage?: string | null
  required?: boolean
  formik: any
  name: string
}

const InputPassword: FC<Props> = ({
  className,
  labelClassName = null,
  label = null,
  size = null,
  openVisibility = true,
  openHighlight = true,
  strengthMessage = null,
  required = false,
  formik,
  name,
}) => {
  let fieldProps = formik.getFieldProps(name)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)

  return (
    <div className={`fv-row fv-plugins-icon-container ${className} `} data-kt-password-meter='true'>
      <div className='mb-1'>
        {label || labelClassName ? (
          <label
            className={`form-label fw-bolder text-dark fs-6 ${labelClassName} ${
              required ? 'required' : ''
            }`}
          >
            {label}
          </label>
        ) : (
          ''
        )}
        <div className='position-relative mb-3'>
          <input
            className={clsx(
              `form-control form-control-${size}`,
              {'border-danger': formikTouched && formikErrors},
              {'border-success': formikTouched && !formikErrors && fieldProps.value}
            )}
            type='password'
            {...fieldProps}
          />

          <span
            className={`btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 ${
              openVisibility ? '' : 'd-none'
            }`}
            data-kt-password-meter-control='visibility'
          >
            <i
              className={clsx('bi bi-eye-slash fs-2', {
                'text-danger': formikTouched && formikErrors,
              })}
            ></i>
            <i
              className={clsx('bi bi-eye fs-2 d-none', {
                'text-danger': formikTouched && formikErrors,
              })}
            ></i>
          </span>
        </div>
        <div
          className={`d-flex align-items-center mb-3 ${openHighlight ? '' : ' d-none'}`}
          data-kt-password-meter-control='highlight'
        >
          <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
          <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
          <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
          <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px' />
        </div>
      </div>
      <div className='text-muted'>{strengthMessage}</div>
      {formikTouched && formikErrors && (
        <div className='fv-plugins-message-container mt-2'>
          <div className='fv-help-block text-danger'>
            <span role='alert'>{formikErrors}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export {InputPassword}
