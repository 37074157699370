import clsx from 'clsx'
import React from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import {checkIsActive, KTSVG} from '../../../helpers'

type Props = {
  className?: string
  classNameLink?: string
  classNameTitle?: string
  to: string
  title: string
  icon?: string
  onClick?: any
  arrowIcon?: boolean
}

const AsideMenuItem: React.FC<Props> = ({
  className = '',
  classNameLink = '',
  classNameTitle = '',
  children,
  to,
  title,
  icon,
  onClick,
  arrowIcon = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)

  return (
    <div className={clsx('menu-item', {active: isActive, [className]: !!className})}>
      <Link
        className={clsx('menu-link', {active: isActive, [classNameLink]: !!classNameLink})}
        to={to}
        onClick={onClick}
      >
        {arrowIcon && <span className='menu-arrow'></span>}
        {icon && (
          <KTSVG path={icon} svgClassName='icon icon-tabler icon-tabler-home-2' small={false} />
        )}
        <span
          className={clsx('menu-title px-3 pt-1', {
            active: isActive,
            [classNameTitle]: !!classNameTitle,
          })}
        >
          {title}
        </span>
      </Link>
      {children}
    </div>
  )
}

export {AsideMenuItem}
