import clsx from 'clsx'
import {FC} from 'react'

type Props = {
  className?: string
  labelClassName?: string | null
  inputClassName?: string | null
  label?: string | null
  size?: 'sm' | 'lg' | null
  type?: 'password' | 'text'
  placeholder?: string
  required?: boolean
  name?: any
  append?: string
  bgSolid?: boolean
  onChange?: any
  value?: any
  disabled?: boolean
}

const PhoneInputNoFormik: FC<Props> = ({
  className,
  labelClassName = '',
  inputClassName = '',
  label = null,
  size = '',
  type = 'text',
  placeholder = '',
  required = false,
  append,
  bgSolid = false,
  onChange,
  value,
  disabled,
}) => {
  return (
    <>
      {label && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <div className={className}>
        <div className='input-group'>
          <input
            type={type}
            autoComplete='off'
            className={clsx(`form-control form-control-${size} ${inputClassName}`, {
              'form-control-solid': bgSolid,
              'cursor-no-drop': disabled,
            })}
            onChange={onChange}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
          />
          {append && (
            <div className='input-group-append'>
              <span className='input-group-text'>{append}</span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export {PhoneInputNoFormik}
