import React, {FC} from 'react'
import clsx from 'clsx'
import {FormikProps, getIn} from 'formik'
import {formatPhoneNumber} from '../../../helpers'

type Props = {
  className?: string
  labelClassName?: string | null
  inputClassName?: string | null
  label?: string | null
  size?: 'sm' | 'lg' | null
  type?: 'password' | 'text'
  placeholder?: string
  required?: boolean
  formik: FormikProps<any>
  name?: any
  append?: string
  bgSolid?: boolean
  disabled?: boolean
}

const PhoneInputFormik: FC<Props> = ({
  className,
  labelClassName = '',
  inputClassName = '',
  label = null,
  size = '',
  type = 'text',
  placeholder = '',
  required = false,
  formik,
  name,
  append,
  bgSolid = false,
  disabled = false,
}) => {
  let fieldProps = formik.getFieldProps(name)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)

  const handleChangeValue = (e) => {
    formik.setFieldValue(fieldProps.name, formatPhoneNumber(e.target.value))
  }

  return (
    <>
      {label && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <div className={className}>
        <div className='input-group'>
          <input
            disabled={disabled}
            type={type}
            autoComplete='off'
            className={clsx(
              `form-control form-control-${size} ${inputClassName}`,
              {'form-control-solid': bgSolid},
              {'is-invalid': formikTouched && formikErrors},
              {'is-valid': formikTouched && !formikErrors && fieldProps.value}
            )}
            {...fieldProps}
            onChange={handleChangeValue}
            value={fieldProps.value}
            placeholder={placeholder}
          />
          {append && (
            <div className='input-group-append'>
              <span className='input-group-text'>{append}</span>
            </div>
          )}
        </div>
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {PhoneInputFormik}
