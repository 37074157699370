import axios, {AxiosRequestConfig} from 'axios'
import HttpStatusCode from './HttpStatusCode'

const API_VERSION = process.env.REACT_APP_API_VERSION
const API_URL = process.env.REACT_APP_LARAVEL_API_URL
const BASE_URL = `${API_URL}/${API_VERSION}`

const get = (path: string, config?: AxiosRequestConfig | undefined) => {
  return new Promise<any>((resolve, reject) => {
    axios
      .get(BASE_URL + path, config)
      .then((response) => {
        if (response && response.status !== HttpStatusCode.OK) {
          processErrorResult(response)
          reject()
        } else {
          resolve(response.data)
        }
      })
      .catch((error) => {
        reject(error)
        //redirect('/Error')
      })
  })
}

const post = (path: string, payload: any, config?: AxiosRequestConfig | undefined) => {
  return new Promise<any>((resolve, reject) => {
    axios
      .post(BASE_URL + path, payload, config)
      .then((response) => {
        if (response && response.status !== HttpStatusCode.OK) {
          processErrorResult(response)
          reject()
        } else {
          resolve(response.data)
        }
      })
      .catch((error) => {
        reject(error)
        //redirect('/Error')
      })
  })
}

const put = (path: string, payload: any, config?: AxiosRequestConfig | undefined) => {
  return new Promise<any>((resolve, reject) => {
    axios
      .put(BASE_URL + path, payload, config)
      .then((response) => {
        if (response && response.status !== HttpStatusCode.OK) {
          processErrorResult(response)
          reject()
        } else {
          resolve(response.data)
        }
      })
      .catch((error) => {
        reject(error)
        //redirect('/Error')
      })
  })
}

const patch = (path: string, payload: any, config?: AxiosRequestConfig | undefined) => {
  return new Promise<any>((resolve, reject) => {
    axios
      .patch(BASE_URL + path, payload, config)
      .then((response) => {
        if (response && response.status !== HttpStatusCode.OK) {
          processErrorResult(response)
          reject()
        } else {
          resolve(response.data)
        }
      })
      .catch((error) => {
        reject(error)
        //redirect('/Error')
      })
  })
}

const remove = (path: string, config?: AxiosRequestConfig | undefined) => {
  return new Promise<any>((resolve, reject) => {
    axios
      .delete(BASE_URL + path, config)
      .then((response) => {
        if (response && response.status !== HttpStatusCode.OK) {
          processErrorResult(response)
          reject()
        } else {
          resolve(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

function processErrorResult(response: any) {
  switch (response.status) {
    case HttpStatusCode.UNAUTHORIZED:
      break
    case HttpStatusCode.BAD_REQUEST:
    case HttpStatusCode.INTERNAL_SERVER_ERROR:
    default:
      redirect('/Error')
  }
}

function redirect(path: string) {
  window.location.href = window.location.origin + path
}

const RequestBase = {
  get,
  post,
  put,
  patch,
  remove,
  redirect,
}

export default RequestBase
