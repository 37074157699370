import axios from 'axios'
import {isEmpty} from 'lodash'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import CarrierService from '../../../app/modules/common/carriers/core/_requests'
import UserService from '../../../app/modules/users/core/_requests'
import {DEFAULT_PAGE, PAGE_SIZE_LOAD_MORE} from '../../constants'
import UseRoutesPermission from '../../hooks/UseRoutesPermission'
import SettingsService from '../../../app/modules/settings/core/_requests'
import {useAuth} from '../../../app/modules/auth'
import {StorageHelpers} from '../StorageHelpers'

const SIGNATURE_ACCESS_KEY = process.env.REACT_APP_SIGNATURE_ACCESS_KEY || 'SIGNATURE_ACCESS_KEY'
const CURRENT_COMPANY_STORAGE_KEY =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE_KEY || 'CURRENT_COMPANY_STORAGE_KEY'
const CURRENT_COMPANY_STORAGE =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE || 'CURRENT_COMPANY_STORAGE'
const STATES_GLOBAL_STORAGE_KEY =
  process.env.REACT_APP_STATES_GLOBAL_STORAGE_KEY || 'STATES_GLOBAL_STORAGE_KEY'
export interface StatesGlobalContextModel {
  statesGlobal: any
  handleUpdateRoutes: any
  handleUpdateAddress: any
  getAddress: any
  getPackages: any
  getPresets: any
  getUsers: any
  setHazmat: any
}

const initStatesGlobalContextPropsStates = {
  statesGlobal: StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY),
  handleUpdateRoutes: () => {},
  handleUpdateAddress: () => {},
  getAddress: () => {},
  getPackages: () => {},
  getPresets: () => {},
  getUsers: () => {},
  setHazmat: () => {},
}

const StatesGlobalContext = React.createContext<StatesGlobalContextModel>(
  initStatesGlobalContextPropsStates
)

const StatesGlobalProvider: React.FC = ({children}) => {
  const companyLocal = StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY)
  let [statesGlobal, setStateGlobal] = useState(
    StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY)
  )
  const [carriers, setCarriers] = useState<Object>(statesGlobal?.carriers)
  const [address, setAddress] = useState<Object>(statesGlobal?.address)
  const [packages, setPackages] = useState<Object>(statesGlobal?.packages)
  const [presets, setPresets] = useState<Object>(statesGlobal?.presets)
  const [users, setUsers] = useState<Object>(statesGlobal?.users)
  const [hazmat, setHazmat] = useState<boolean>(statesGlobal?.hazmat || true)
  const {routes} = UseRoutesPermission()
  const {currentCompany} = useAuth()
  const BASE_DOMAIN = process.env.REACT_APP_DOMAIN

  // begin: state global carriers
  const getCarriers = useCallback(async () => {
    try {
      const response = await CarrierService.getAll()
      if (response) {
        setCarriers(response.carriers)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(carriers)) {
      return (axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = companyLocal)
    }
    getCarriers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCarriers])
  // end: state global carriers

  // begin: state global address
  const getAddress = useCallback(async () => {
    try {
      const [fromAddress, toAddress] = await Promise.all(
        ['from', 'to'].map((name) =>
          SettingsService.getOptionsAddress({
            params: {type: name, page: DEFAULT_PAGE, page_size: PAGE_SIZE_LOAD_MORE},
          })
        )
      )
      if (fromAddress && toAddress) {
        setAddress({
          from: fromAddress.shipping_addresses,
          to: toAddress.shipping_addresses,
        })
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  const handleUpdateAddress = useCallback((updateAddress) => {
    setAddress((prev) => ({...prev, ...updateAddress}))
  }, [])

  useEffect(() => {
    if (!isEmpty(address)) {
      return (axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = companyLocal)
    }

    getAddress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAddress])
  // end: state global address

  // begin: state global packages
  const getPackages = useCallback(async () => {
    try {
      const config = {
        params: {
          page: DEFAULT_PAGE,
          page_size: PAGE_SIZE_LOAD_MORE,
        },
      }
      const {packages} = await SettingsService.getPackages(config)
      if (packages) {
        setPackages(packages)
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(packages)) {
      return (axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = companyLocal)
    }

    getPackages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPackages])
  // end: state global packages

  // begin: state global presets
  const getPresets = useCallback(async () => {
    try {
      const config = {
        params: {
          page: DEFAULT_PAGE,
          page_size: PAGE_SIZE_LOAD_MORE,
        },
      }

      const {orders} = await SettingsService.getShippingPresets(config)
      if (orders) {
        setPresets(orders)
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(presets)) {
      return (axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = companyLocal)
    }

    getPresets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPresets])
  // end: state global presets

  // begin: state global users
  const getUsers = useCallback(async () => {
    const config = {
      params: {
        page: DEFAULT_PAGE,
        page_size: PAGE_SIZE_LOAD_MORE,
        status: 'active',
        flag_user_assign_order: true,
      },
    }
    try {
      if (!routes.USERS.hasPermission || !routes.ORDERS_ASSIGN.hasPermission) return
      const response = await UserService.getListUserGlobal(config)
      if (response) {
        setUsers(response.users)
      }
    } catch (error) {
      console.log(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEmpty(users)) {
      return (axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = companyLocal)
    }

    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsers])
  // end: state global users

  // start: state global routes
  const handleUpdateRoutes = useCallback((updateRoute) => {
    const storageData = StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY)
    StorageHelpers.setItemLocalStorage(STATES_GLOBAL_STORAGE_KEY, {
      ...storageData,
      routes: updateRoute,
    })
  }, [])
  // end: state global routes

  // Update global state when making the first API call.
  useEffect(() => {
    StorageHelpers.setItemLocalStorage(STATES_GLOBAL_STORAGE_KEY, {
      carriers: carriers,
      address: address,
      packages: packages,
      presets: presets,
      users: users,
      hazmat: hazmat,
    })

    setStateGlobal(StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY))
  }, [address, carriers, packages, presets, users, hazmat])

  useEffect(() => {
    const company = StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE)
    StorageHelpers.setItemCookies('slug', company?.slug, {
      path: '/',
      domain: BASE_DOMAIN?.split(':')[0],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany])

  return (
    <StatesGlobalContext.Provider
      value={{
        statesGlobal,
        handleUpdateRoutes,
        handleUpdateAddress,
        getAddress,
        getPackages,
        getPresets,
        getUsers,
        setHazmat,
      }}
    >
      {children}
    </StatesGlobalContext.Provider>
  )
}

function useStatesGlobal() {
  return useContext(StatesGlobalContext)
}

export {StatesGlobalProvider, useStatesGlobal}
