import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'

type Props = {
  show: boolean
  action?: string
  messageCancel?: string
  message: any
  confirmMessage?: string
  cancelMessage?: string
  showCancelButton?: boolean
  handleClose: (status?: boolean) => void
  handleCallBack: any
  outsideClick?: boolean
}

const ConfirmActionSwal: React.FC<Props> = ({
  show,
  action,
  message,
  confirmMessage,
  messageCancel,
  cancelMessage,
  showCancelButton = true,
  handleClose,
  handleCallBack,
  outsideClick = true,
}) => {
  const intl = useIntl()

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn fw-bold btn-danger',
      cancelButton: 'btn fw-bold btn-active-light-primary',
    },
    buttonsStyling: false,
    scrollbarPadding: false,
    heightAuto: false,
  })

  useEffect(() => {
    show &&
      swalWithBootstrapButtons
        .fire({
          allowOutsideClick: outsideClick,
          html: message,
          icon: 'warning',
          showCancelButton: showCancelButton,

          confirmButtonText:
            intl.formatMessage({id: 'YES'}) +
            (action ? ' , ' + action?.toLocaleLowerCase() + '!' : ''),
          cancelButtonText: messageCancel ? messageCancel : intl.formatMessage({id: 'NO_CANCEL'}),
        })
        .then((result) => {
          if (result.isConfirmed) {
            handleCallBack()
            if (confirmMessage) {
              swalWithBootstrapButtons.fire({
                text: confirmMessage,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn fw-bold btn-primary',
                },
                confirmButtonText: intl.formatMessage({id: 'OK_GOT_IT'}),
              })
            }
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            if (cancelMessage) {
              swalWithBootstrapButtons.fire({
                text: cancelMessage,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn fw-bold btn-primary',
                },
                confirmButtonText: intl.formatMessage({id: 'OK_GOT_IT'}),
              })
            }
          }
          handleClose()
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return <></>
}

export {ConfirmActionSwal}
