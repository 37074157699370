import {cloneDeep, isEqual} from 'lodash'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Container, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import displayConfig from '../../../../displayconfig.json'
import {Button} from '../form/Button'

type Props = {
  show: boolean
  handleClose: () => void
  handleCustomsColumn: any
  columns: any
  initColumns?: any
}

const CustomsColumnTable: React.FC<Props> = ({
  show,
  columns,
  initColumns,
  handleCustomsColumn,
  handleClose,
}) => {
  const intl = useIntl()
  let draggedItem = useRef()
  const [itemsDrop, setItemsDrop] = useState<any>([])

  useEffect(() => {
    const initColumnId = new Set(initColumns.map(({id}) => id))
    const columnId = cloneDeep(columns).map((item) => item.id)
    const hideArr = cloneDeep(columns).filter(({id}) => !initColumnId.has(id))

    const arrExpend = initColumns.map((item) => ({...item, show: true}))

    hideArr.forEach((hiddenItem) => {
      const columnIndex = columnId.indexOf(hiddenItem.id)
      if (columnIndex !== -1) {
        arrExpend.splice(columnIndex, 0, hiddenItem)
      }
    })

    setItemsDrop(arrExpend)
  }, [columns, initColumns])

  const onDragStart = useCallback(
    (e, index) => {
      draggedItem.current = cloneDeep(
        itemsDrop.filter(({id}) => id !== 'canCheck' && id !== 'sub-actions')
      )[index]
      e.dataTransfer.effectAllowed = 'move'
      e.dataTransfer.setData('text/html', e.target.parentNode)
      e.dataTransfer.setDragImage(e.target.parentNode, 20, 20)
    },
    [itemsDrop]
  )

  const onDragOver = useCallback(
    (index) => {
      if (!itemsDrop || !draggedItem.current) return
      const _itemsDrop = cloneDeep(itemsDrop)

      if (draggedItem.current === cloneDeep(_itemsDrop[index])) {
        return
      }
      let updatedItems = cloneDeep(_itemsDrop).filter((item) => !isEqual(item, draggedItem.current))
      updatedItems.splice(index, 0, draggedItem.current)
      setItemsDrop(updatedItems)
    },
    [itemsDrop]
  )

  const onDragEnd = useCallback(() => {
    draggedItem.current = undefined
  }, [])

  const handleChangeCheckbox = useCallback(
    (itemCheck) => {
      const newItems = cloneDeep(itemsDrop).map((item) => {
        if (isEqual(item, itemCheck)) {
          item.show = !item.show
        }
        return item
      })
      setItemsDrop(newItems)
    },
    [itemsDrop]
  )

  const handleChangePin = (itemPin) => {
    const newItems = cloneDeep(itemsDrop).map((item) => {
      if (isEqual(item, itemPin)) {
        item.pin = !item.pin
      }
      return item
    })
    setItemsDrop(newItems)
  }

  return (
    <Modal
      id='gori_table_modal'
      tabIndex={-1}
      aria-hidden='true'
      centered
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'MANAGE_COLUMNS'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='mh-300px scroll-y'>
          <Container>
            {cloneDeep(itemsDrop)
              .filter(({id}) => id !== 'canCheck' && id !== 'sub-actions')
              .map((item, idx) => (
                <div className='d-flex my-2' key={idx} onDragOver={() => onDragOver(idx)}>
                  <div
                    className='d-flex align-items-center cursor-move flex-fill justify-content-between bg-light bg-hover-secondary p-2 px-4 rounded-2 '
                    draggable
                    onDragStart={(e) => onDragStart(e, idx)}
                    onDragEnd={onDragEnd}
                  >
                    <div className='d-flex align-items-center'>
                      <label className='form-check form-check-custom form-check-white'>
                        <input
                          type='checkbox'
                          className='form-check-input cursor-pointer'
                          checked={item?.show || false}
                          onChange={() => handleChangeCheckbox(item)}
                        />
                      </label>

                      <span className='ms-4 fs-5 fw-bold'>{item.Header}</span>
                    </div>
                    {displayConfig.items.table.pin && (
                      <div onClick={() => handleChangePin(item)} className='cursor-pointer'>
                        {item.pin ? (
                          <div className='pin'>
                            <div className='pinned'>{intl.formatMessage({id: 'PINNED'})}</div>
                            <div className='unpin'>{intl.formatMessage({id: 'UNPIN'})}</div>
                          </div>
                        ) : (
                          <div>{intl.formatMessage({id: 'PIN'})}</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <Button
              className='btn btn-secondary me-3'
              label={intl.formatMessage({id: 'CANCEL'})}
              event={() => handleClose()}
            />
            <Button
              className='btn btn-primary'
              label={intl.formatMessage({id: 'SAVE_COLUMNS'})}
              event={() => {
                handleCustomsColumn(itemsDrop)
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export {CustomsColumnTable}
