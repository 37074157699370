import {useEffect, useRef} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {useAuth} from '../../app/modules/auth'
import {MenuComponent, SwapperComponent} from '../assets/ts/components'
import {LoadingPage} from '../helpers/components/LoadingPage'
import {MeterProvider} from '../helpers/components/MeterProvider'
import {StatesGlobalProvider} from '../helpers/components/StatesGlobalProvider'
import {ActivityDrawer} from '../partials'
import {AsideDefault} from './components/aside/AsideDefault'
import {Content} from './components/Content'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {PageDataProvider} from './core'

const MasterLayout = () => {
  const location = useLocation()
  const {loadingSwitch} = useAuth()
  const timer: any = useRef()

  useEffect(() => {
    timer.current = setTimeout(() => {
      SwapperComponent.reinitialization()
      MenuComponent.reinitialization()
    }, 500)
    return () => {
      clearTimeout(timer.current)
    }
  }, [location.key])

  return (
    <PageDataProvider>
      <MeterProvider>
        <StatesGlobalProvider>
          {loadingSwitch && <LoadingPage />}
          <div className='d-flex flex-column flex-root'>
            <div className='page d-flex flex-row flex-column-fluid'>
              <AsideDefault />
              <div className='wrapper d-flex flex-column flex-row-fluid' id='gori_wrapper'>
                <HeaderWrapper />

                <div id='gori_content' className='content d-flex flex-column flex-column-fluid'>
                  <Content>
                    <Outlet />
                  </Content>
                </div>
                <Footer />
              </div>
            </div>
          </div>

          {/* begin:: Drawers */}
          <ActivityDrawer />
          {/* end:: Drawers */}
          <ScrollTop />
        </StatesGlobalProvider>
      </MeterProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
