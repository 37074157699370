import {StorageHelpers} from '../../../../_gori/helpers'
import AuthRequestBase from '../../../api/AuthRequestBase'
import {AxiosRequestConfig} from 'axios'

const CURRENT_COMPANY_STORAGE_KEY =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE_KEY || 'CURRENT_COMPANY_STORAGE_KEY'
const API_VERSION = process.env.REACT_APP_API_VERSION
const BASE_URL = `${API_VERSION}/users`

function getUser(config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.get(`/${BASE_URL}`, config)
}

function getMeter() {
  const currentCompany = StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY)
  return AuthRequestBase.get(
    `/${BASE_URL}/meter${currentCompany ? `?selected=${currentCompany}` : ''}`
  )
}

function getListUser(config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.get(`/${BASE_URL}/list`, config)
}

function getListUserGlobal(config?: AxiosRequestConfig | undefined) {
  const currentCompany = StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY)
  return AuthRequestBase.get(`/${BASE_URL}/list?selected=${currentCompany}`, config)
}

function showUser(config?: AxiosRequestConfig | undefined, userId?: any) {
  return AuthRequestBase.get(`/${BASE_URL}/show/${userId}`, config)
}

function getPermissions() {
  return AuthRequestBase.get(`/${API_VERSION}/permissions`)
}

function getRestrictions() {
  return AuthRequestBase.get(`/${API_VERSION}/restriction`)
}

const store = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.post(`/${BASE_URL}/create`, payload, config)
}

const edit = (payload: any, config?: AxiosRequestConfig | undefined, userId?: any) => {
  return AuthRequestBase.put(`/${BASE_URL}/edit/${userId}`, payload, config)
}

const update = (payload: any, config?: AxiosRequestConfig | undefined, userId?: any) => {
  return AuthRequestBase.post(`/${BASE_URL}/update/${userId}`, payload, config)
}

function getUserByEmail(email) {
  return AuthRequestBase.get(`/${BASE_URL}/search-email?email=${email}`)
}

const inviteUser = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.post(`/${BASE_URL}/invite`, payload, config)
}

function removeUser(userId) {
  return AuthRequestBase.remove(`/${BASE_URL}/remove-from-company/${userId}`)
}

const UserService = {
  getUser,
  getMeter,
  getListUser,
  getPermissions,
  getRestrictions,
  store,
  showUser,
  update,
  edit,
  getUserByEmail,
  getListUserGlobal,
  inviteUser,
  removeUser,
}

export default UserService
