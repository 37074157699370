import {useCallback} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ClaimsConfig} from '../../app/modules/claims/core/_const'
import {
  REGEX_COMPANY_SLUG,
  REGEX_EMAIL,
  REGEX_PASSWORD,
  REGEX_PHONE,
  REGEX_SHOPIFY_SUBDOMAIN,
  REGEX_STATE,
  REGEX_URL,
  REGEX_ZIP_CODE,
} from '../constants'
import {yupHelper} from '../helpers'
import {UNIT_VALUES} from '../../app/modules/orders/core/_const'

const UseYupValidate = () => {
  const intl = useIntl()
  const yup = yupHelper(intl)

  const stringYup = (valueLabel, name, required = true) => {
    switch (valueLabel) {
      case 'no_limit':
        return yup.string({
          name: name,
          required: required,
          trim: true,
        })

      default:
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {value: valueLabel, label: valueLabel},
        })
    }
  }

  const numberYup = {
    parcel: useCallback(
      (name, required = true, packageType: 'hasPackage' | '' = '') => {
        let yup = Yup.number()
          .nullable()
          .typeError(
            intl.formatMessage(
              {id: 'INPUT_CAN_ONLY_BE_FILLED_IN_DIGITS'},
              {input: intl.formatMessage({id: name.toUpperCase()})}
            )
          )
          .min(
            0.1,
            intl.formatMessage(
              {id: 'INPUT_MUST_BE_GREATER_THAN'},
              {input: intl.formatMessage({id: name.toUpperCase()}), max: 0}
            )
          )
        if (name === 'weight') {
          Object.entries(
            packageType === 'hasPackage' ? UNIT_VALUES.WEIGHT_CARRIER : UNIT_VALUES.WEIGHT
          ).forEach(([unit, {max}]: any) => {
            yup = yup.when(['weight_unit'], {
              is: unit,
              then: Yup.number()
                .nullable()
                .max(
                  max,
                  intl.formatMessage(
                    {id: 'INPUT_MUST_NOT_BE_GREATER_THAN'},
                    {input: intl.formatMessage({id: name.toUpperCase()}), max: `${max} ${unit}`}
                  )
                ),
            })
          })
        } else {
          Object.entries(UNIT_VALUES.DIMENSION).forEach(([unit, {max}]: any) => {
            yup = yup.when(['dimension_unit'], {
              is: unit,
              then: Yup.number()
                .nullable()
                .max(
                  max,
                  intl.formatMessage(
                    {id: 'INPUT_MUST_NOT_BE_GREATER_THAN'},
                    {input: intl.formatMessage({id: name.toUpperCase()}), max: `${max} ${unit}`}
                  )
                ),
            })
          })
        }

        if (required) {
          yup = yup.required(
            intl.formatMessage(
              {id: 'INPUT_CANNOT_BE_BLANK'},
              {input: intl.formatMessage({id: name.toUpperCase()})}
            )
          )
        }
        return yup
      },
      [intl]
    ),
    unitPrice: useCallback(
      (name) => {
        return yup.number({
          name: name,
          required: true,
          trim: true,
          min: {
            value: 0.1,
            label: 0,
          },
          max: {
            value: 1000000,
            label: '$1.000.000',
          },
        })
      },
      [yup]
    ),
    quantity: yup.number({
      name: 'QUANTITY',
      required: true,
      trim: true,
      integer: true,
      min: {
        value: 0.1,
        label: 0,
      },
      max: {
        value: 1000000,
        label: '1.000.000',
      },
    }),
    amount: useCallback(
      (name, value = 10000000, label = '$10.000.000') => {
        return yup.number({
          name: name,
          required: true,
          trim: true,
          min: {
            value: 0.1,
            label: 0,
          },
          max: {
            value: value,
            label: label,
          },
        })
      },
      [yup]
    ),
  }

  const regexYup = {
    country: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_CANNOT_BE_BLANK'},
          {input: intl.formatMessage({id: 'COUNTRY'})}
        )
      )
      .max(
        2,
        intl.formatMessage(
          {id: 'PLEASE_USE_A_TWO_LETTER_CODE'},
          {input: intl.formatMessage({id: 'COUNTRY'})}
        )
      )
      .nullable()
      .trim(),
    state: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_CANNOT_BE_BLANK'},
          {input: intl.formatMessage({id: 'STATE'})}
        )
      )
      .matches(
        REGEX_STATE,
        intl.formatMessage(
          {id: 'PLEASE_USE_A_TWO_LETTER_CODE'},
          {input: intl.formatMessage({id: 'STATE'})}
        )
      )
      .max(
        2,
        intl.formatMessage(
          {id: 'PLEASE_USE_A_TWO_LETTER_CODE'},
          {input: intl.formatMessage({id: 'STATE'})}
        )
      )
      .nullable()
      .trim(),
    zipCode: yup.string({
      name: 'POSTAL_CODE',
      required: true,
      trim: true,
      max: {
        value: 255,
        label: 255,
      },
      regex: {
        value: REGEX_ZIP_CODE,
        label: 'THE_VALUE_IS_NOT_A_VALID_ZIP_CODE',
      },
    }),
    url: useCallback(
      (name, required = true) => {
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {
            value: 50,
            label: 50,
          },
          regex: {
            value: REGEX_URL,
            label: 'PLEASE_ENTER_VALID_URL',
          },
        })
      },
      [yup]
    ),
    shop: useCallback(
      (name, required = true) => {
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {
            value: 255,
            label: 255,
          },
          regex: {
            value: REGEX_SHOPIFY_SUBDOMAIN,
            label: 'PLEASE_ENTER_VALID_SHOPIFY_SUBDOMAIN',
          },
        })
      },
      [yup]
    ),
    companySlug: useCallback(
      (name, required = true) => {
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {
            value: 255,
            label: 255,
          },
          regex: {
            value: REGEX_COMPANY_SLUG,
            label: 'PLEASE_ENTER_VALID_SUBDOMAIN',
          },
        })
      },
      [yup]
    ),
    storeLink: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: 'Shopify ' + intl.formatMessage({id: 'STORE_DOMAIN'})}
        )
      )
      .matches(
        REGEX_SHOPIFY_SUBDOMAIN,
        intl.formatMessage({id: 'PLEASE_ENTER_VALID_SHOPIFY_SUBDOMAIN'})
      )
      .max(
        255,
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
          {input: 'Shopify ' + intl.formatMessage({id: 'STORE_DOMAIN'}), max: 255}
        )
      )
      .trim(),
    customLink: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_CANNOT_BE_BLANK'},
          {input: intl.formatMessage({id: 'CUSTOM_LINK'})}
        )
      )
      .matches(REGEX_URL, intl.formatMessage({id: 'PLEASE_ENTER_VALID_URL'}))
      .max(
        255,
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
          {input: intl.formatMessage({id: 'CUSTOM_LINK'}), max: 255}
        )
      )
      .trim(),
  }

  const arrayRequiredYup = useCallback(
    (name, min = 1) => {
      return Yup.array().min(
        min,
        intl.formatMessage({id: 'INPUT_IS_REQUIRED'}, {input: intl.formatMessage({id: name})})
      )
    },
    [intl]
  )

  const dateRequiredYup = useCallback(
    (name) => {
      return Yup.date()
        .transform((value, originalValue) => {
          if (originalValue === 'Invalid date' || !originalValue) {
            return null
          }
          return value
        })
        .nullable()
        .required(
          intl.formatMessage({id: 'INPUT_CANNOT_BE_BLANK'}, {input: intl.formatMessage({id: name})})
        )
    },
    [intl]
  )

  const infoYup = {
    phone: useCallback(
      (name = 'PHONE') => {
        return yup.string({
          name: name,
          required: true,
          trim: true,
          max: {
            value: 50,
            label: 50,
          },
          regex: {
            value: REGEX_PHONE,
            label: 'PLEASE_ENTER_VALID_PHONE',
          },
        })
      },
      [yup]
    ),

    email: useCallback(
      (name = 'EMAIL', required = true) => {
        return yup.string({
          name: name,
          required: required,
          trim: true,
          max: {
            value: 50,
            label: 50,
          },
          regex: {
            value: REGEX_EMAIL,
            label: 'PLEASE_ENTER_VALID_EMAIL_ADDRESS',
          },
        })
      },
      [yup]
    ),
  }

  // BEGIN: auth
  const authYup = {
    password: useCallback(
      (required = true) => {
        return yup.string({
          name: 'PASSWORD',
          required: required,
          trim: true,
          max: {
            value: 50,
            label: 50,
          },
          regex: {
            value: REGEX_PASSWORD,
            label: 'PLEASE_ENTER_VALID_PASSWORD',
          },
        })
      },
      [yup]
    ),
    passwordConfirmation: useCallback(
      (required = true) => {
        return yup.string({
          name: 'CONFIRM_PASSWORD',
          required: required,
          trim: true,
          max: {
            value: 50,
            label: 50,
          },
          when: {
            target: 'password',
            is: (val: string) => !!(val && val.length > 0),
            then: Yup.string().oneOf(
              [Yup.ref('password')],
              intl.formatMessage({id: 'PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH'})
            ),
          },
        })
      },
      [intl, yup]
    ),
    acceptTerms: Yup.bool().oneOf(
      [true],
      intl.formatMessage({id: 'YOU_MUST_ACCEPT_THE_TERMS_AND_CONDITIONS'})
    ),
  }
  // END: auth

  // BEGIN: Claims
  const claimYup = {
    specificItemType: yup.string({
      name: 'SPECIFIC_ITEM_TYPE',
      when: {
        target: 'claim_item_type',
        is: (val) => val === ClaimsConfig.ITEM_TYPE_OTHER,
        then: stringYup(50, 'SPECIFIC_ITEM_TYPE'),
      },
    }),

    replacementTrackingNumber: yup.string({
      name: 'REPLACEMENT_TRACKING_NUMBER',
      when: {
        target: 'claim_is_replace_package',
        is: true,
        then: stringYup(50, 'REPLACEMENT_TRACKING_NUMBER'),
      },
    }),
  }
  // END: Claims

  // BEGIN: Orders
  const orderYup = {
    companyFirstName: useCallback(
      (name) => {
        return Yup.string()
          .required(
            intl.formatMessage(
              {id: 'INPUT_REQUIRED_IF_EMPTY'},
              {
                input: intl.formatMessage({id: name}),
                value: intl.formatMessage({id: name === 'FIRST_NAME' ? 'COMPANY' : 'FIRST_NAME'}),
              }
            )
          )
          .max(
            255,
            intl.formatMessage(
              {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
              {input: name, max: 255}
            )
          )
          .nullable()
          .trim()
      },
      [intl]
    ),

    insuredAmount: Yup.number()
      .typeError(
        intl.formatMessage(
          {id: 'INPUT_CAN_ONLY_BE_FILLED_IN_DIGITS'},
          {input: intl.formatMessage({id: 'INSURANCE_AMOUNT'})}
        )
      )
      .min(
        0,
        intl.formatMessage(
          {id: 'INPUT_MUST_BE_GREATER_THAN_OR_EQUAL_THAN'},
          {
            input: intl.formatMessage({id: 'INSURANCE_AMOUNT'}),
            max: 0,
          }
        )
      )
      .max(
        5000,
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN'},
          {
            input: intl.formatMessage({id: 'INSURANCE_AMOUNT'}),
            max: '5000',
          }
        )
      )
      .nullable(),
    lbAndOz: Yup.object()
      .shape({
        weight_lb: yup.number({
          name: 'WEIGHT_LB',
          trim: true,
          min: {
            value: 0.1,
            label: 0,
          },
        }),
        weight_oz: yup.number({
          name: 'WEIGHT_OZ',
          trim: true,
          min: {
            value: 0.1,
            label: 0,
          },
        }),
      })
      .test(
        'WEIGHT',
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN'},
          {
            input: intl.formatMessage({id: 'WEIGHT'}),
            max: 10000,
          }
        ),
        function (values) {
          const {weight_lb, weight_oz} = values
          const totalWeight = (weight_lb || 0) * 16 + (weight_oz || 0)
          return totalWeight <= 10000
        }
      ),
    checkWeight: Yup.bool().oneOf(
      [false],
      intl.formatMessage({
        id: 'PACKAGE_WEIGHT_MUST_BE_HEAVIER_OR_EQUAL_TO_CUSTOMS_ITEMS_WEIGHT',
      })
    ),
  }
  // END: Orders

  // BEGIN: Batches
  const batchesYup = {
    existingBatch: Yup.object().required(
      intl.formatMessage(
        {id: 'INPUT_CANNOT_BE_BLANK'},
        {input: intl.formatMessage({id: 'SELECT_A_BATCH'})}
      )
    ),
  }
  // END: Batches

  // BEGIN: settings
  const settingsYup = {
    token: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: 'ShipStation ' + intl.formatMessage({id: 'TOKEN'})}
        )
      )
      .trim(),
    apiKey: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: 'ShipStation' + intl.formatMessage({id: 'API_KEY'})}
        )
      )
      .max(
        255,
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
          {input: 'ShipStation' + intl.formatMessage({id: 'API_KEY'}), max: 255}
        )
      )
      .trim(),
    secretKey: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'INPUT_IS_REQUIRED'},
          {input: 'ShipStation ' + intl.formatMessage({id: 'API_SECRET'})}
        )
      )
      .max(
        255,
        intl.formatMessage(
          {id: 'INPUT_MUST_NOT_BE_GREATER_THAN_CHARACTERS'},
          {input: 'ShipStation ' + intl.formatMessage({id: 'API_SECRET'}), max: 255}
        )
      )
      .trim(),
  }
  // END: settings

  return {
    stringYup,
    numberYup,
    regexYup,
    arrayRequiredYup,
    dateRequiredYup,
    infoYup,
    authYup,
    claimYup,
    orderYup,
    batchesYup,
    settingsYup,
  }
}

export default UseYupValidate
