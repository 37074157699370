import React from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from '../header-menus/Languages'
import UseRoutesPermission from '../../../hooks/UseRoutesPermission'

const UserMenu = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const {routes} = UseRoutesPermission()
  const handleLogout = () => {
    navigate('/logout')
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      {/* begin::Menu item */}
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          {/* begin::Username */}
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name + ' ' + currentUser?.last_name}
            </div>
            <span className='fw-bold text-muted fs-7'>
              {intl.formatMessage({id: currentUser?.role.toUpperCase().replaceAll(' ', '_')})}
            </span>
          </div>
          {/* end::Username */}
        </div>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu separator */}
      <div className='separator my-2' />
      {/* end::Menu separator */}

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <Link to={'/my-profile'} className='menu-link px-5'>
          {intl.formatMessage({id: 'MY_PROFILE'})}
        </Link>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu item */}
      {routes.PAYMENTS?.hasPermission && (
        <div className='menu-item px-5'>
          <Link to={'/deposits'} className='menu-link px-5'>
            {intl.formatMessage({id: 'DEPOSIT_TO_WALLET'})}
          </Link>
        </div>
      )}
      {/* end::Menu item */}

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <Link to={'/settings/api-keys'} className='menu-link px-5'>
          {intl.formatMessage({id: 'API_KEYS'})}
        </Link>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu separator */}
      <div className='separator my-2'> </div>
      {/* end::Menu separator */}

      <Languages languageMenuPlacement='right-end' />

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <span onClick={() => handleLogout()} className='menu-link px-5'>
          {intl.formatMessage({id: 'SIGN_OUT'})}
        </span>
      </div>
      {/* end::Menu item */}
    </div>
  )
}

export {UserMenu}
