import clsx from 'clsx'
import React from 'react'
import {useLocation} from 'react-router'
import {KTSVG, checkIsActiveSubRoute} from '../../../helpers'
import {useLayout} from '../../core'

type Props = {
  className?: string
  classNameLink?: string
  classNameTitle?: string
  title: string
  icon?: string
  subRoute?: Array<string>
  dropdown?: 'left' | 'right'
}

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  className = '',
  classNameLink = '',
  classNameTitle = '',
  title,
  icon,
  subRoute = [],
  dropdown = 'right',
}) => {
  const {config} = useLayout()
  const {aside} = config
  const {pathname} = useLocation()
  const isActive = checkIsActiveSubRoute(subRoute, pathname)

  return (
    <div
      className={clsx('menu-item menu-accordion', {
        'active hover show': isActive,
        [className]: !!className,
      })}
      data-kt-menu-trigger='click'
    >
      <div className={clsx('menu-link', {active: isActive, [classNameLink]: !!classNameLink})}>
        {dropdown === 'left' && <span className='menu-arrow'></span>}
        {icon && aside.menuIcon === 'svg' && (
          <KTSVG path={icon} svgClassName='icon icon-tabler icon-tabler-home-2' small={false} />
        )}
        <span
          className={clsx('menu-title px-3 pt-1', {
            active: isActive,
            [classNameTitle]: !!classNameTitle,
          })}
        >
          {title}
        </span>
        {dropdown === 'right' && <span className='menu-arrow'></span>}
      </div>
      <div className={clsx('menu-sub menu-sub-accordion pb-2', {'menu-active-bg': isActive})}>
        {children}
      </div>
    </div>
  )
}

export {AsideMenuItemWithSub}
