import * as XLSX from 'xlsx/xlsx.mjs'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {TIMEZONES} from '../constants'
import {StorageHelpers} from './StorageHelpers'

const PERMISSIONS_STORAGE_KEY =
  process.env.REACT_APP_PERMISSIONS_STORAGE_KEY || 'PERMISSIONS_STORAGE_KEY'
const BASE_DOMAIN = process.env.REACT_APP_DOMAIN

const allowTestOnly = (currentUser) => {
  return currentUser?.username === 'test' || currentUser?.username === 'admin'
}

const checkPermission = (keyPermission: string) => {
  const permission = StorageHelpers.getItemLocalStorage(PERMISSIONS_STORAGE_KEY)

  if (!permission) return false
  return permission.indexOf(keyPermission) > -1
}

const setLoadingActionTable = (
  tableData: Array<object>,
  listId: Array<number>,
  status: boolean
) => {
  const setIsLoading = tableData.filter((row: any) => {
    listId.forEach((id: number) => {
      if (row.id === id) {
        row.isLoading = status
      }
    })
    return listId.includes(row.id)
  })

  return tableData.map((row: any) => setIsLoading.find((item: any) => item.id === row.id) || row)
}

const checkRowHaveData = async (file) => {
  const data = await file.arrayBuffer()
  const wb = XLSX.read(data)
  const ws = wb.Sheets[wb.SheetNames[0]]
  const range = XLSX.utils.decode_range(ws['!ref'])
  let row = range.e.r - range.s.r + 1
  const dataExcel = XLSX.utils.sheet_to_json(ws, {header: 1, blankRows: false})

  for (let i = 0; i < dataExcel.length; i++) {
    if (dataExcel[i].length === 0) {
      row -= 1
    }
  }
  return row
}

const checkIncludes = (text, array) => {
  return array.some((el) => {
    return text.includes(el)
  })
}

const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const formatPhoneNumber = (value) => {
  if (!value) return value

  const phoneNumber = value.replace(/[^\d]/g, '')
  const phoneNumberLength = phoneNumber.length

  if (phoneNumberLength < 4) return phoneNumber
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}

const getShipmentFees = (fees: any) => {
  let sum = 0
  if (!isEmpty(fees)) {
    sum = fees.reduce((accumulator: any, object: any) => {
      return accumulator + object?.amount ?? 0
    }, 0)
  }

  return sum
}

const convertCurrency = (amount, currency = '$') => {
  if (!amount || Number(amount) === 0) {
    return '-'
  }
  return currency + '' + new Intl.NumberFormat('en').format(amount)
}

const convertUserTimeZone = (datetime: string, currentUser: any, format: any) => {
  const timezoneConst: any = TIMEZONES.find(
    (tz) => tz.value === currentUser?.user_profiles?.general?.timezone?.value
  )
  let convertedDate = moment(datetime).add(timezoneConst?.offset * 60, 'minutes')
  if (currentUser?.user_profiles?.general?.timezone?.dst) {
    convertedDate.add(60, 'minutes')
  }
  return convertedDate.format(format)
}

const hasNoSubdomain = () => {
  const domain = BASE_DOMAIN?.split(':')[0]
  return document.location.hostname === domain
}

function convertUnit(
  fromUnit: {value: number; unit: string},
  toUnit: {value?: number; unit: string}
) {
  const conversionTable = {
    in: {cm: 2.54, mm: 25.4},
    cm: {in: 0.393701, mm: 10},
    mm: {in: 0.0393701, cm: 0.1},
    oz: {lb: 0.0625, gm: 28.3495, kg: 0.0283495},
    lb: {oz: 16, gm: 453.592, kg: 0.453592},
    gm: {oz: 0.035274, lb: 0.00220462, kg: 0.001},
    kg: {oz: 35.274, lb: 2.20462, gm: 1000},
  }

  if (fromUnit.unit === toUnit.unit) {
    return fromUnit.value
  } else if (conversionTable[fromUnit.unit] && conversionTable[fromUnit.unit][toUnit.unit]) {
    const conversionRate = conversionTable[fromUnit.unit][toUnit.unit]
    return fromUnit.value * conversionRate
  } else {
    return 0
  }
}

export {
  allowTestOnly,
  checkPermission,
  setLoadingActionTable,
  checkRowHaveData,
  checkIncludes,
  formatBytes,
  formatPhoneNumber,
  getShipmentFees,
  convertCurrency,
  convertUserTimeZone,
  hasNoSubdomain,
  convertUnit,
}
