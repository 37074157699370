import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {IHeader, useLayout} from '../../core'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import {useAuth} from '../../../../app/modules/auth'
import {SwitchCompany} from '../../../helpers/components/SwitchCompany'
import {configureUI} from '../../../constants'

const calculateStickyOffset = (header: IHeader): string => {
  if (header.fixed.desktop && header.fixed.tabletAndMobile) {
    return '{default: "200px", lg: "300px"}'
  }

  if (header.fixed.desktop && !header.fixed.tabletAndMobile) {
    return '{lg: "300px"}'
  }

  if (header.fixed.tabletAndMobile && !header.fixed.desktop) {
    return '{default: "200px", lg: false}'
  }

  return ''
}

const calculateShowSticky = (header: IHeader): boolean => {
  return (
    (header.fixed.desktop && header.fixed.tabletAndMobile) ||
    (header.fixed.desktop && !header.fixed.tabletAndMobile) ||
    (header.fixed.tabletAndMobile && !header.fixed.desktop)
  )
}

export function HeaderWrapper() {
  const {currentUser} = useAuth()
  const {config, attributes} = useLayout()
  const {header} = config
  const [stickyOffset, setStickyOffset] = useState<string>(calculateStickyOffset(header))
  const [showSticky, setShowSticky] = useState<boolean>(calculateShowSticky(header))
  useEffect(() => {
    setStickyOffset(calculateStickyOffset(header))
    setShowSticky(calculateShowSticky(header))
  }, [header])

  const isRouteDashboard = () => {
    return window.location.pathname.includes('/dashboard')
  }

  return (
    <div
      id='gori_header'
      className='mx-10 mx-xxl-10'
      {...attributes.headerMenu}
      data-kt-sticky={showSticky ? 'true' : 'false'}
      data-kt-sticky-name='header'
      data-kt-sticky-offset={stickyOffset}
    >
      <div className='m-xxl-0 d-flex flex-stack flex-wrap gap-2 mt-5' id='gori_header_container'>
        <DefaultTitle />
        {/* begin::Wrapper */}
        <div className='d-flex d-xxl-none align-items-center ms-n2 me-2'>
          {/* begin::Aside mobile toggle */}
          <div className='btn btn-icon btn-active-icon-primary' id='gori_aside_toggle'>
            <KTSVG path='/media/gori/aside/toggle.svg' className='svg-icon-1 mt-1' />
          </div>
          {/* end::Aside mobile toggle */}

          {/* begin::Logo */}

          <Link to='/dashboard' className='d-flex align-items-center'>
            <img
              alt='Logo'
              src={
                configureUI.urlLogo.tabletAside
                  ? configureUI.urlLogo.tabletAside
                  : toAbsoluteUrl('/media/logos/shippingitnow-7.png')
              }
              // className='h-25px'
            />
          </Link>
          {currentUser && <SwitchCompany isAside={false} className='ms-3' />}
          {/* end::Logo */}
        </div>
        {/* end::Wrapper */}
        {!isRouteDashboard() && <Topbar />}
      </div>
    </div>
  )
}
