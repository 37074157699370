import {CSSTransition} from 'react-transition-group'
import {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import {LoadingPage} from '../../../_gori/helpers/components/LoadingPage'
import {StorageHelpers} from '../../../_gori/helpers'

export function GlobalShopifyVertifyPage() {
  const slug = StorageHelpers.getItemCookies('slug')
  const [searchParams] = useSearchParams()
  const BASE_DOMAIN = process.env.REACT_APP_DOMAIN

  useEffect(() => {
    let queryString: string[] = []
    searchParams.forEach((value, key) => {
      let param = key + '=' + value
      queryString.push(param.toString())
    })
    const protocol = document.location.protocol
    const url =
      protocol +
      '//' +
      slug +
      '.' +
      BASE_DOMAIN +
      '/settings/connections/shopify/verify?' +
      queryString.join('&')
    if (slug) {
      document.location.replace(url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, slug])

  return (
    <CSSTransition appear in timeout={100} classNames='fade' unmountOnExit>
      <LoadingPage />
    </CSSTransition>
  )
}
