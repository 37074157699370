import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {checkPermission, KTSVG} from '../../../helpers'
import {SwitchCompany} from '../../../helpers/components/SwitchCompany'
import {KEY_PERMISSIONS} from '../../../constants'
import {UserMenu} from '../user-menu/UserMenu'

const AsideUserMenu: FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      <div className='d-flex flex-stack'>
        {/* begin::Wrapper */}
        <div className='d-flex align-items-center'>
          {/* begin::User info */}
          <div className='ms-2'>
            <Link to='/my-profile' className='text-gray-800 text-hover-primary fs-6 fw-bolder lh-1'>
              {currentUser?.first_name + ' ' + currentUser?.last_name}
            </Link>
            <span className='text-muted fw-bold d-block fs-7 lh-1'>
              {intl.formatMessage({id: currentUser?.role.toUpperCase().replaceAll(' ', '_')})}
            </span>
          </div>
          {/* end::User info */}
        </div>
        {/* end::Wrapper */}

        {/* begin::User menu */}
        <div className='ms-1'>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary position-relative me-n2'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='false'
            data-kt-menu-placement='top-end'
          >
            <KTSVG path='/media/gori/settings.svg' className='svg-icon-1' />
          </div>
          <UserMenu />
        </div>
        {/* end::User menu */}
      </div>
      <div className='ms-2'>
        {checkPermission(KEY_PERMISSIONS.ADMIN) && (
          <span>Current version: #{process.env.REACT_APP_COMMIT_ID}</span>
        )}
      </div>
      <SwitchCompany isAside className='mt-3' />
      <div className='d-flex justify-content-center mt-5'>
        <Link
          className='text-decoration-underline text-dark text-hover-primary fw-bold'
          to='/support'
        >
          <KTSVG
            path={'/media/gori/aside/support.svg'}
            className='svg-icon-4 me-1 text-dark text-hover-primary'
          />
          Support
        </Link>
      </div>
    </>
  )
}

export {AsideUserMenu}
